/* import "~slick-carousel/slick/slick.css"; 
import "~slick-carousel/slick/slick-theme.css"; */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slid1{
  background: linear-gradient(to bottom right, #12193b 0%, #94ff 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid2{
  background: linear-gradient(to bottom right, #5730a7 0%, #22025f 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid3{
  background: linear-gradient(to bottom right, #3b72ef 0%, #39d5aa 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid4{
  background: linear-gradient(to bottom right, #45aff5 0%, #00043f 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid5{
  background: linear-gradient(to bottom right, #ff2ad0 0%, #010920 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid6{
  background: linear-gradient(to bottom right, #5ae1e5 0%, #012132 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid7{

  background: linear-gradient(to bottom right, #f7be68 0%, #f24a4a 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh

}
.slid8{
  background: linear-gradient(to bottom right, #5c6e97 0%, #011c41 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
.slid9{
  background: linear-gradient(to bottom right, #e67c25 0%, #260029 100%);
  padding-top: 80px;
  padding-bottom:100px;
  height: 100vh
}
div#myCarousel ul.slick-dots {
  position: relative;
  bottom: 50px !important;
  margin-bottom: -40px;
  /* left: 97%;
  display: grid !important;
  color: #fff !important; */
  /* top: 0px; */
  /* list-style: disc !important; */
  /* display: inline-block !important; */
}