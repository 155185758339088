@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Montserrat", sans-serif !important;
  color: #111111 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

/* Input Number */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

/* Input Number */

.services.oneto a {
  display: none;
}

.close {
  display: none;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slid0 {
  background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid1 {
  background: linear-gradient(to bottom right, #12193b 0%, #94ff 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid2 {
  background: linear-gradient(to bottom right, #5730a7 0%, #22025f 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid3 {
  background: linear-gradient(to bottom right, #3b72ef 1, #39d5aa 1);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid4 {
  background: linear-gradient(to bottom right, #45aff5 0%, #00043f 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid5 {
  background: linear-gradient(to bottom right, #ff2ad0 0%, #010920 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid6 {
  background: linear-gradient(to bottom right, #5ae1e5 0%, #012132 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid7 {
  background: linear-gradient(to bottom right, #f7be68 0%, #f24a4a 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid8 {
  background: linear-gradient(to bottom right, #5c6e97 0%, #011c41 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

.slid9 {
  background: linear-gradient(to bottom right, #e67c25 0%, #260029 100%);
  height: 100vh;
  min-height: 100vh;
  padding: 0rem;
}

div#myCarousel ul.slick-dots {
  position: relative;
  bottom: 50px !important;
  margin-bottom: -40px;
}

.main-form textarea {
  background-color: transparent;
}

section.slid0 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid1 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid2 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid3 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid4 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid5 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid6 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid7 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid8 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

section.slid9 img {
  display: block;
  margin: 0 auto;
  height: auto;
}

.qode-hero-image img {
  display: block;
  margin: 0 auto;
}

ul.platform li {
  list-style: none;
}

.inside-sub-li {
  position: relative;
  cursor: pointer;
}

.other-solution a.nav-link h2 {
  text-align: center;
  color: #000;
}

.other-consulting a.nav-link h2 {
  text-align: center;
  color: #000;
}

::-webkit-input-placeholder {
  color: #fff !important;
}

.inside-sub-li:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 5px;
  margin: -5px 0;
  background-color: black;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out 0s;
}

.inside-sub-li:hover:before {
  visibility: visible;
  transform: scaleX(1);
  font-weight: bold;
}

.row.king {
  position: relative;
}

.main-form form.row.g-3 button:hover {
  background: #f24a4a;
  color: #fff;
  border: 1px solid #fff;
}

.item-SB p a {
  text-decoration: none;
}

h2.slidehead {
  font-size: 3.125rem;
  animation-delay: 1.5s;
  animation-duration: 1s;
  font-weight: 600;
  padding-bottom: 25px;
  padding-left: 20px;
}

::placeholder {
  color: #fff;

  opacity: 1;
}

::-ms-input-placeholder {
  color: #fff;
}

.choce-btg-head h1 {
  text-align: center;
  font-size: 3.75rem;
  font-weight: 800;
  padding-bottom: 40px;
}

.main-form textarea.form-control {
  height: 200px;
  background-color: transparent;
}

section.main-banner {
  text-align: center;
  background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
  /* display: flex; */
  /* align-items: center; */
  /* padding: 5rem 0rem; */
}

.sec06 h2,
.sec02 h2,
.sec03 h2,
.sec05 h2,
.sec050 h2,
.sec055 h2,
.sec0555 h2,
.sec06 h2,
.sec09 h2,
.sec099 h2,
.sec0999 h2,
.sec0999 h2,
.sec0101 h2 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 4.25rem;
  font-weight: 700;
}

.choce-btg-head h1 {
  text-align: center;
  font-size: 3.75rem;
  font-weight: 800;
  padding-bottom: 20px;
  color: #fff;
  padding-top: 30px;
}

/* bouquet section start  */

/* bouquet section end  */

section.mainbanner-sec {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  vertical-align: middle;
  padding-bottom: 0px;
}


.qode-expanding-images-inner img {
  display: block;
  margin: 0 auto;
}

.Bucketimg {
  position: relative;
}

.qode-expanding-images-inner {
  position: relative;
}

.qode-hero-image {
  position: absolute;
  top: 95px;
  left: 0%;
  right: 0%;
}

.mainbanner-head {
  display: flex;
  vertical-align: middle;
  position: relative;
}

.top-bar-border {
  background-color: #ffffff;
  height: 5px;
  width: 8%;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

section.howwe-sec {
  background: linear-gradient(135deg, #141e30 0, #243b55 100%);
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.howmain-head h3 {
  color: #fff;
  font-size: 2.25rem;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
  text-align: center;
}

.howworkpara p {
  font-size: 1.563rem !important;
  line-height: 38px;
  font-style: normal;
  font-weight: 400;
}

section.WhyWork-sec {
  background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Whymain-head h3 {
  color: #fff;
  font-size: 2.25rem;
  text-align: center;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
}

.whyworkpara p {
  font-size: 1.563rem !important;
  line-height: 38px;
  font-style: normal;
  font-weight: 400;
}

section.OurWork-sec {
  height: 103vh;
  background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);

  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 60px;
}

.ourmain-head h3 {
  color: #fff;
  font-size: 2.25rem;
  text-align: center;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
}

.ourworkpara p {
  font-size: 1.563rem !important;
  line-height: 38px;
  font-style: normal;
  font-weight: 400;
}

.one-img {
  position: absolute;
  top: 40px;
}

.two-img {
  position: absolute;
  top: 40px;
  left: 300px;
}

.three-img {
  position: absolute;
  top: 300px;
  left: 300px;
}

.four-img {
  position: absolute;
  top: 300px;
}

.das {
  background-color: #00c;
}

@keyframes one-img {
  from {
    margin-left: 100%;
    width: 3000%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.main-heading h3 {
  color: #191919;
  font-family: inherit;
  font-size: 3.125rem;
  line-height: 60px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -1.25px;
  text-transform: none;
}

.main-heading p {
  color: #716e6e;
  font-family: inherit;
  font-size: 1.625rem;
  line-height: 38px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
}

/* service page css start   */
select:focus-visible {
  outline: none;
}

.digital-images.Fintech-img.sasas ul li img {
  width: 160px;
}

.digital-images.Fintech-img.assa ul li img {
  width: 160px;
}

section.digital-sec.maintain {
  background: linear-gradient(to bottom right,
      #fffb7b 0%,
      #fddede 100%) !important;
}

section.digital-sec.bigecom {
  background: linear-gradient(135deg, #a4e199e3 0, #30efd5e0 100%) !important;
}

section.digital-sec.magen {
  background: linear-gradient(to bottom right,
      #5aa8b1 0%,
      #600000cf 100%) !important;
}

.left-img.unity3d {
  background: linear-gradient(135deg, #38597e9c 0, #4d5a70de 100%) !important;
}

h3.big {
  color: #000 !important;
}

section.digital-sec.ppc {
  background: linear-gradient(to bottom right,
      #8ea9a5 0%,
      #e2ff8c 100%) !important;
}

section.Fintech-sec.woo {
  background: linear-gradient(to bottom right,
      #bd98ff 0%,
      #f7b0b0 100%) !important;
}

.left-img.virtual {
  background: linear-gradient(to bottom right,
      #04f7af 0%,
      #68687280 100%) !important;
}

.left-img.magen {
  background: linear-gradient(to bottom right,
      #600000cf 0%,
      #5aa8b1ad 100%) !important;
}

.left-img.augm {
  background: linear-gradient(to bottom right,
      #afef33bf 0%,
      #a15454c2 100%) !important;
}

.left-img.marketan {
  background: linear-gradient(135deg, #369994e6 0, #8196bdc9 100%) !important;
}

h3.meanangu {
  color: #000 !important;
}

section.digital-sec.python {
  background: linear-gradient(to bottom right,
      #04f7af 0%,
      #000099 100%) !important;
}

section.Fintech-sec.uianimation {
  background: linear-gradient(to bottom right,
      #39a350 0%,
      #f98a49 100%) !important;
}

.left-img.produc {
  background: linear-gradient(135deg, #243b55 0, #141e309c 100%) !important;
}

section.digital-sec.brand {
  background: linear-gradient(to bottom right,
      #c9c559 0%,
      #fbadad 100%) !important;
}

p.meanangu {
  color: #000;
}

section.digital-sec.ang {
  background: linear-gradient(135deg, #47fd87 0, #e9f4f5 100%) !important;
}

.left-img.pyth {
  background: linear-gradient(to bottom right, #000099 0%, #04f7af9e 100%);
}

.left-img.google-pay {
  background: linear-gradient(to bottom right,
      #e2ff8c 0%,
      #8ea9a59e 100%) !important;
}

section.digital-sec.uxse {
  background: linear-gradient(to bottom right,
      #8eb1a7 0%,
      #57eb92 100%) !important;
}

section.Fintech-sec.shop {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

section.digital-sec.virtual {
  background: linear-gradient(to bottom right,
      #7e7ebfc7 0%,
      #04f7af 100%) !important;
}

section.Fintech-sec.casual {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #c0c756 100%) !important;
}

section.Fintech-sec.metav {
  background: linear-gradient(to bottom right,
      #bfaae3 0%,
      #ef9f9fc7 100%) !important;
}

section.Fintech-sec.wallet {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

section.Fintech-sec.exchang {
  background: linear-gradient(to bottom right,
      #fffb7b 0%,
      #a95f5f 100%) !important;
}

section.Fintech-sec.smart {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

.left-img.hyper {
  background: linear-gradient(to bottom right,
      #edcccc 0%,
      #8f8ef9a6 100%) !important;
}

h2.heading-1.big {
  color: #000;
}

section.digital-sec.nft {
  background: linear-gradient(to bottom right,
      #c0ade3 0%,
      #d39e4f85 100%) !important;
}

p.big {
  color: #000;
}

section.digital-sec.socialm {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

.left-img.bigcome {
  background: linear-gradient(135deg, #30efd5e0 0, #a4e199bf 100%) !important;
}

.Leverage-head {
  margin-bottom: 60px;
}

span.meansas {
  color: #000 !important;
}

.left-img.uxrese {
  background: linear-gradient(to bottom right, #8eb1a7 0%, #57eb929e 100%);
}

section.digital-sec.unity {
  background: linear-gradient(135deg, #4d5a70de 0, #38597e9c 100%) !important;
}

section.digital-sec.hyper {
  background: linear-gradient(to bottom right,
      #8f8ef9 0%,
      #edcccc 100%) !important;
}

.left-img.ethere {
  background: linear-gradient(to bottom right,
      #bebcff 0%,
      #786e6ea1 100%) !important;
}

section.digital-sec.ethere {
  background: linear-gradient(to bottom right,
      #bf4747 0%,
      #bebcff 100%) !important;
}

.left-img.privat {
  background: linear-gradient(to bottom right,
      #276d9f 0%,
      #dfff929c 100%) !important;
}

section.digital-sec.private {
  background: linear-gradient(to bottom right,
      #829555 0%,
      #68995f 100%) !important;
}

section.Fintech-sec.content {
  background: linear-gradient(to bottom right,
      #93a55beb 0%,
      #9f2727 100%) !important;
}

section.digital-sec.marketan {
  background: linear-gradient(135deg, #8196bdc9 0, #369994e6 100%) !important;
}

.left-img.emailma {
  background: linear-gradient(to bottom right,
      #560707 0%,
      #87a38782 100%) !important;
}

section.digital-sec.email {
  background: linear-gradient(to bottom right,
      #87a387 0%,
      #560707 100%) !important;
}

section.Fintech-sec1.fint {
  background: linear-gradient(to bottom right,
      #5b9ea5d1 0%,
      #992f2feb 100%) !important;
}

.left-img.demand {
  background: linear-gradient(to bottom right,
      #9f2727 0%,
      #765ba5a6 100%) !important;
}

section.digital-sec.saas {
  background: linear-gradient(to bottom right,
      #93afa6 0%,
      #74dd5c 100%) !important;
}

.left-img.realstat {
  background: linear-gradient(to bottom right,
      #919093 0%,
      #2fabc17d 100%) !important;
}

section.digital-sec.realstate {
  background: linear-gradient(to bottom right,
      #2fabc1 0%,
      #919093 100%) !important;
}

section.Fintech-sec1.integration {
  background: linear-gradient(to bottom right,
      #ab6f77a6 0%,
      #a728288a 100%) !important;
}

.left-img.mainten {
  background: linear-gradient(to bottom right,
      #fddede 0%,
      #fffb7b82 100%) !important;
}

.left-img.webapp {
  background: linear-gradient(to bottom right,
      #a1dbaec2 0%,
      #c6eda3bf 100%) !important;
}

section.digital-sec.webapp {
  background: linear-gradient(to bottom right,
      #c6eda3bf 0%,
      #a1dbaec2 100%) !important;
}

section.Fintech-sec1.devops {
  background: linear-gradient(to bottom right,
      #abb79bd9 0%,
      #ffc7c7c4 100%) !important;
}

.left-img.design-con {
  background: linear-gradient(to bottom right,
      #c580d7bf 0%,
      #cfa444d9 100%) !important;
}

section.digital-sec.design {
  background: linear-gradient(to bottom right,
      #dbb35ad9 0%,
      #f7eff9bf 100%) !important;
}

.left-img.testing {
  background: linear-gradient(to bottom right,
      #e174ded1 0%,
      #d7d94f 100%) !important;
}

section.digital-sec.testing {
  background: linear-gradient(to bottom right,
      #d7d94f 0%,
      #e174ded1 100%) !important;
}

.left-img.cloud {
  background: linear-gradient(to bottom right,
      #299f27bf 0%,
      #84d3cf96 100%) !important;
}

section.digital-sec.cloud {
  background: linear-gradient(to bottom right,
      #84d3cf96 0%,
      #299f27bf 100%) !important;
}

section.Service-img.cloud {
  height: 500px;
  width: 100%;
  background-image: url("./images/consulting/cloud/cloudconsulting-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.testing {
  height: 500px;
  width: 100%;
  background-image: url("./images/consulting/testing/testingconsulting-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.design-con {
  height: 500px;
  width: 100%;
  background-image: url("./images/consulting/design/designconsulting_headerpic01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.webapp {
  height: 500px;
  width: 100%;
  background-image: url("./images/consulting/WebApp/webappconsulting-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.mainten {
  height: 500px;
  width: 100%;
  background-image: url("./images/solutions/Maintenance/maintenancesupport-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img.nft {
  background: linear-gradient(to bottom right,
      #d39e4f85 0%,
      #c0ade3 100%) !important;
}

section.Service-img.realstat {
  height: 500px;
  width: 100%;
  background-image: url("./images/solutions/Realstate/realestateapp_headerpic01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.demand {
  height: 500px;
  width: 100%;
  background-image: url("./images/solutions/demand/ondemanapp-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.digital-sec.demand {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

.left-img.saas {
  background: linear-gradient(to bottom right,
      #74dd5c 0%,
      #93afa6a6 100%) !important;
}

section.Service-img.saas {
  height: 500px;
  width: 100%;
  background-image: url("./images/solutions/saas/saas-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.emailma {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/digital-market/email/emailmarketing-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.nft {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/blockchain/nft/nft-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Fintech-sec.Seo {
  background: linear-gradient(to bottom right,
      #3e2301cc 0%,
      #d3ab51d4 100%) !important;
}

section.Service-img.social-media {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/digital-market/social-maedia/socialmediamarketing-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img.social-media {
  background: linear-gradient(to bottom right,
      #9f2727 0%,
      #765ba58c 100%) !important;
}

section.Fintech-sec.mobile-m {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #cf2121 100%) !important;
}

section.Service-img.marketan {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/blockchain/privat/privateblockchain_header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.privat {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/blockchain/privat/privateblockchain_header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.ethere {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/blockchain/ethereum/ethereum-_header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.google-pay {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/digital-market/ppc/ppc-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.hyper {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/blockchain/hyperledger/hyperledger-header.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.augm {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/game/augmentsreality/augmentsreality-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.unity {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/game/unity3d/unity3d-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.magen {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/E-commerce/Magento/magento-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.virtual {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/game/virtualreal/virtualreality-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.bigcom {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/E-commerce/Big-commerce/bigcommerce-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.uxres {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/Uiux/Uxre/uiuxresearch-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.type {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/web-dev/Laravel/laravel-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.poduc {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/Uiux/Product/productdesign-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img.Brands {
  background: linear-gradient(to bottom right,
      #fbadad 0%,
      #c9c5598c 100%) !important;
}

.mainbanner-img img {
  vertical-align: middle;
}

section.Service-img.Brand {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/Uiux/brand/brand-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.pyth {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/web-dev/python/phython-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.laravel {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/web-dev/typescript/typescript-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.Angular {
  height: 500px;
  width: 100%;
  background-image: url("./images/indeximg/web-dev/Angular/angular-header01.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img.Angular h2 {
  color: #000;
}

.left-img.Angular p {
  color: #000;
}

.short-lineAng {
  background-color: #000 !important;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.left-img.type {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698);
  padding: 50px;
  background: linear-gradient(135deg, #9fe5bb 0, #9987078f 100%) !important;
}

section.digital-sec.Laravel {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

.left-img.Angular {
  background: linear-gradient(135deg, #bcdafd 0, #47fd8773 100%) !important;
}

a.carousel-control-next {
  display: none;
}

a.carousel-control-prev {
  display: none;
}

.digital-images.Fintech-img.sa ul {
  margin-left: 70px;
}

.digital-sec {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(135deg, #1a7262 0, #3b618d 100%) !important;
}

section.Service-img.swift {
  background-image: url(./images/Services/Swift/swift-header01.png);
}

section.Fintech-sec.asp {
  background: linear-gradient(to bottom right,
      #ac96d3 0%,
      #cf7777 100%) !important;
}

section.Fintech-sec.Php {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #08a1cfa8 100%) !important;
}

section.digital-sec.sweift {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

.left-img.swift {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f27278f 100%) !important;
}

.left-img.laravel {
  width: 70%;
  height: 400px;
  padding: 50px;
  background: linear-gradient(to bottom right,
      #9f2727 0%,
      #765ba59e 100%) !important;
}

.Ai-chatbot {
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.Service-img {
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698);
  padding: 50px;
}

.short-line {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3.125rem;
  color: #ffffff;
  line-height: 50px;
}

.short-line,
p {
  font-size: 1.875rem;
  color: #ffffff;
  line-height: 40px;
}

select:focus-visible {
  outline: none;
}

.left-img.las {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727a3 100%) !important;
}

section.Service-img.imd {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/kotlin/kotlin-header01.png);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.digital-sec {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(135deg, #1a7262 0, #3b618d 100%) !important;
}

.Ai-chatbot {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/AI-Chatbot/AIchatbot-Headerimage.jpg);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.Service-img {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/androidapp-header01.png);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698);
  padding: 50px;
}

.short-line {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3.125rem;
  color: #ffffff;
  line-height: 50px;
}

.short-line,
p {
  margin: 10px 0px;
  font-size: 1.875rem;
  color: #ffffff;
  line-height: 40px;
}

section.digital-sec.sasa {
  background: linear-gradient(to bottom right,
      #765ba5 0%,
      #9f2727 100%) !important;
}

select:focus-visible {
  outline: none;
}

section.Service-img.sda {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/iphone/androidapp-heade01.png);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698);
  padding: 50px;
}

.short-line {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3.125rem;
  color: #ffffff;
  line-height: 50px;
}

.short-line,
p {
  margin: 10px 0px;
  font-size: 1.875rem;
  color: #ffffff;
  line-height: 40px;
}

.left-img1 {
  background: linear-gradient(to bottom right,
      #04f7af 0%,
      #0000996b 100%) !important;
}

section.digital-sec {
  padding-top: 50px !important;
  padding-right: 30px !important;
  padding-bottom: 30px !important;
  padding-left: 30px !important;
  background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
  padding-top: 3px;
}

select:focus-visible {
  outline: none;
}

section.digital-sec.ms {
  background: linear-gradient(135deg, #141e30 0, #243b55 100%) !important;
}

section.Service-img.dss {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/Prompt-Engineering/promptengi-headerimage.jpg);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.ds {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/hybridapp/hybridapp-header01.png);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img.left-img2 {
  background: linear-gradient(135deg, #141e30 0, #243b55ad 100%) !important;
}

.left-img2 {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698) !important;
  padding: 50px;
}

.short-line {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3.125rem;
  color: #ffffff;
  line-height: 50px;
}

.short-line,
p {
  margin: 10px 0px;
  font-size: 1.875rem;
  color: #ffffff;
  line-height: 40px;
}

.digital-sec {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(135deg, #141e30 0, #243b55ad 100%) !important;
}

select:focus-visible {
  outline: none;
}

.Ai-chatbot {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/AI-Chatbot/AIchatbot-Headerimage.jpg);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.Service-img {
  height: 500px;
  width: 100%;
  background-image: url(./images/Services/androidapp-header01.png);
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698);
  padding: 50px;
}

.short-line {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3.125rem;
  color: #ffffff;
  line-height: 50px;
}

.short-line,
p {
  margin: 10px 0px;
  font-size: 1.875rem;
  color: #ffffff;
  line-height: 40px;
}

.digital-sec {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(135deg, #1a7262 0, #3b618d 100%) !important;
}

.digital-sec {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(135deg, #1a7262 0, #3b618d 100%) !important;
}

.left-img.asas {
  background: linear-gradient(to bottom right, #04f7af 0%, #00009980 100%);
  height: 400px;
  width: 70%;
  height: 400px;
  padding: 50px;
}

select:focus-visible {
  outline: none;
}

section.Service-img.sasasas {
  background-image: url(./images/Services/Data-training/AIDatatraining-headerimage02.jpg);
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

section.Service-img.sasasa {
  background-image: url(./images/Services/Reas=ct/react-header01.png);
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.Ai-chatbot {
  margin-top: 5.5rem;
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.Service-img {
  margin-top: 5.5rem;
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 50px 0px;
  padding: 50px 0px;
  background-position: bottom right !important;
  background-repeat: no-repeat !important;
  background-size: initial !important;
}

.left-img {
  width: 70%;
  height: 400px;
  background-image: linear-gradient(to right, #227569, #6d8ea698);
  padding: 50px;
}

.short-line {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.heading-1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #ffffff;
  line-height: 50px;
}

.short-line,
p {
  font-size: 1.225rem;
  color: #ffffff;
  line-height: 40px;
}

/* Nevbar css start  */

.main-img img {
  position: relative;
}

.main-heabs h3 {
  z-index: 99999999;
  font-family: Montserrat;
  height: auto;
  width: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: inherit;
  line-height: 52px;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 2.813rem;
  transform-origin: 50% 50%;
  transform: translate(0px, 0px);
  opacity: 1;
  visibility: visible;
}

.main-heabs h3 {
  position: absolute;
  top: 195px;
  z-index: 999;
  left: 0%;
  right: 0%;
  color: #fff;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
}

section.Fintech-sec.flutt {
  background: linear-gradient(to bottom right,
      #a79696 0%,
      #d2e921 100%) !important;
}

section.Fintech-sec.ssdsa {
  background: linear-gradient(to bottom right,
      #72c5c1 0%,
      #f778ad 100%) !important;
}

section.Fintech-sec.ionic {
  background: linear-gradient(to bottom right,
      #d585b1 0%,
      #dfba51 100%) !important;
}

span.main-logo.navbar-brand img {
  width: 400px;
  height: auto;
  display: block;
  margin: 0 auto;
}

#Main-Img-round-AI1 {
  border-radius: 25px;
  border: 2px solid #df9926;
  background: url("./images/hover-menu/ai-images/chatbot-sidemenu.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AI2 {
  border-radius: 25px;
  border: 2px solid #df9926;
  background: url("./images/hover-menu/ai-images/promptengi-sidemenu.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AI3 {
  border-radius: 25px;
  border: 2px solid #df9926;
  background: url("./images/hover-menu/ai-images/aidatatraining-sidemenu.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-MS {
  border-radius: 25px;
  border: 2px solid #df9926;
  background: url("./images/hover-menu/mobile-app-side/Android.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-WS {
  border-radius: 25px;
  border: 2px solid #963596;

  background: url("./images/hover-menu/mobile-app-side/Iphone.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-US {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/mobile-app-side/Kotlink.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-ES {
  border-radius: 25px;
  border: 2px solid #0f9ffa;

  background: url("./images/hover-menu/mobile-app-side/Flutter.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-GS {
  border-radius: 25px;
  border: 2px solid #df9926;
  background: url("./images/hover-menu/mobile-app-side/Ionic.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-BS {
  border-radius: 25px;
  border: 2px solid #963596;

  background: url("./images/hover-menu/mobile-app-side/Xamarine.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-DS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/mobile-app-side/hybridapp.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-MSI {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/mobile-app-side/Swift.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-WS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/mobile-app-side/React-navtive.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-BSS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/Asp.net.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/phython.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-CS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/uxuidesigning/UI&Animation.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-FS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/laravel.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-GFS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/Angular.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-HS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/mobile-app-side/Iphone.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-JS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/uxuidesigning/DesignTesting.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-KS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/mobile-app-side//Swift.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-LS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/uxuidesigning/Brandstrategy.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-NS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/uxuidesigning/productdesign.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-OS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/uxuidesigning/UX-Research.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-PS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/ecommerce/Shopify.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-QS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/ecommerce/Big-Commerce.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-SS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/ecommerce/Wocommerce.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-TS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/ecommerce/magento.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-VS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/games_development/unity3d.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-XS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/games_development/Augmented-reality.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-YS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/games_development/Virtual-reality.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-ZS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/games_development/casual-game.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AB {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/games_development/Metaverse.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AC {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/games_development/unreal-engine.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AD {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/wallet.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AE {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/exchange.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AF {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/ethereum.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AG {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/private-blockchain.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AH {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/NFT-Marketplace.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AI {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/hyperledger.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AJ {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/block-chain/smartcontract.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AK {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/seo.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AL {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/emailmarketing.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AM {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/marketinganalytics.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AN {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/ppc.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AO {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/mobilemarketing.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AP {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/affiliatemarketing.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AQ {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/socialmediamarketing.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AR {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/digital-marketing/contentmarketing.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-ASS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/Php.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-AT {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/wordpress.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

#Main-Img-round-TSSS {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/hover-menu/web/Typescript.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
}

@media screen and (max-height: 450px) {
  #Main-Img-round-TSSS {
    display: none;
  }

  #Main-Img-round-AI1{
    display: none;
  }

  #Main-Img-round-AI2{
    display: none;
  }

  #Main-Img-round-AI3{
    display: none;
  }

  #Main-Img-round-MS {
    display: none;
  }

  #Main-Img-round-WS {
    display: none;
  }

  #Main-Img-round-US {
    display: none;
  }

  #Main-Img-round-ES {
    display: none;
  }

  #Main-Img-round-GS {
    display: none;
  }

  #Main-Img-round-BS {
    display: none;
  }

  #Main-Img-round-BSS {
    display: none;
  }

  #Main-Img-round-DS {
    display: none;
  }

  #Main-Img-round-AS {
    display: none;
  }

  #Main-Img-round-CS {
    display: none;
  }

  #Main-Img-round-FS {
    display: none;
  }

  #Main-Img-round-GFS {
    display: none;
  }

  #Main-Img-round-HS {
    display: none;
  }

  #Main-Img-round-JS {
    display: none;
  }

  #Main-Img-round-KS {
    display: none;
  }

  #Main-Img-round-LS {
    display: none;
  }

  #Main-Img-round-NS {
    display: none;
  }

  #Main-Img-round-OS {
    display: none;
  }

  #Main-Img-round-PS {
    display: none;
  }

  #Main-Img-round-QS {
    display: none;
  }

  #Main-Img-round-SS {
    display: none;
  }

  #Main-Img-round-TS {
    display: none;
  }

  #Main-Img-round-VS {
    display: none;
  }

  #Main-Img-round-XS {
    display: none;
  }

  #Main-Img-round-YS {
    display: none;
  }

  #Main-Img-round-ZS {
    display: none;
  }

  #Main-Img-round-AB {
    display: none;
  }

  #Main-Img-round-AC {
    display: none;
  }

  #Main-Img-round-AD {
    display: none;
  }

  #Main-Img-round-AE {
    display: none;
  }

  #Main-Img-round-AF {
    display: none;
  }

  #Main-Img-round-AG {
    display: none;
  }

  #Main-Img-round-AH {
    display: none;
  }

  #Main-Img-round-AI {
    display: none;
  }

  #Main-Img-round-AJ {
    display: none;
  }

  #Main-Img-round-AK {
    display: none;
  }

  #Main-Img-round-AL {
    display: none;
  }

  #Main-Img-round-AM {
    display: none;
  }

  #Main-Img-round-AN {
    display: none;
  }

  #Main-Img-round-AO {
    display: none;
  }

  #Main-Img-round-AP {
    display: none;
  }

  #Main-Img-round-AQ {
    display: none;
  }

  #Main-Img-round-AR {
    display: none;
  }

  #Main-Img-round-ASS {
    display: none;
  }

  #Main-Img-round-AT {
    display: none;
  }
}

.other-element {
  display: none;
}

a.men.nav-link .other-element:hover {
  display: block;
}

.other-solution {
  display: none;
}

.other-consulting {
  display: none;
}

a.consulting-sec.nav-link:hover .other-consulting {
  display: block;
  transition: 0.5s;
}

.other-consulting {
  background-color: #f1f2f2;
  transition: 0.5s;
  width: calc(100vw - 399px);
  height: 100%;
  position: absolute;
  background-blend-mode: overlay;
  top: 0px;
  right: 100%;
}

a.solution-sec.nav-link .other-solution:hover {
  display: block;
}

a.solution-sec.nav-link:hover .other-solution {
  display: block;
  transition: 0.5s;
}

.other-solution {
  background-color: #f1f2f2;
  transition: 0.5s;
  width: calc(100vw - 399px);
  height: 100%;
  position: absolute;
  background-blend-mode: overlay;
  top: 0px;
  right: 100%;
}

.other-element {
  overflow: scroll;
  background-color: #f1f2f2;
  transition: 0.5s;
  width: calc(100vw - 399px);
  height: 100%;
  position: absolute;
  background-blend-mode: overlay;
  top: 0px;
  right: 100%;
}

a.men.nav-link:hover .other-element {
  display: block;
}

.head-txt {
  font-size: 2.188rem;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1.1;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  color: #ffffff;
}

.h-50vh {
  height: 40vh !important;
  width: 100% !important;
  border-radius: 25px;
  border: 2px solid #963596;
  margin: 10px;
}

.h-50vh:hover {
  border: 2px solid #df9926;
}

.bg-img-pos {
  background-position: 0 -30px !important;
}

.bg-img-pos:hover {
  background-position: 14px -124px !important;
}

.mac,
.doc,
.wco,
.ccc,
.ttc,
.ddc,
.fts,
.ods,
.sas,
.res,
.mss,
.iam {
  height: 100vh;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  color: #fff;
  padding-top: 25px;
}

.and-img {
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/menu-icon/consulting sub menu/Mobileapp-consulting.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
  display: none;
}

.and-img:hover {
  position: absolute;
  right: 0px;
  display: block;
  border-radius: 25px;
  border: 2px solid #008080;
  background: url("./images/menu-icon/consulting sub menu/Mobileapp-consulting.png");
  padding: 20px;
  width: 280px;
  height: 170px;
  margin-left: 30px;
  z-index: 9;
}

.mac {
  background: #df9926 url("./images/menu-icon/consulting sub menu/Mobileapp-consulting.png") no-repeat scroll -150px 65px;
}

.doc {
  background: #0f9ffa url("./images/menu-icon/consulting sub menu/devops-consulting.png") no-repeat scroll -20px 65px;
}

.wco {
  background: #963596 url("./images/menu-icon/consulting sub menu/web-consulting.png") no-repeat scroll 0 65px;
}

.ccc {
  background: #c9dbcb url("./images/menu-icon/consulting sub menu/cloud-consulting.png") no-repeat scroll 0 65px;
}

.ttc {
  background: #df9926 url("./images/menu-icon/consulting sub menu/testing-consulting.png") no-repeat scroll -150px 65px;
}

.ddc {
  background: #0f9ffa url("./images/menu-icon/consulting sub menu/design-consulting.png") no-repeat scroll -20px 65px;
}

.fts {
  background: #963596 url("./images/menu-icon/solutions sub menu/Fintech-development.png") no-repeat scroll 0 65px;
}

.ods {
  background: #c9dbcb url("./images/menu-icon/solutions sub menu/ondemand-app.png") no-repeat scroll 0 65px;
}

.sas {
  background: #df9926 url("./images/menu-icon/solutions sub menu/saas-development.png") no-repeat scroll -150px 65px;
}

.res {
  background: #0f9ffa url("./images/menu-icon/solutions sub menu/realestateapp-development.png") no-repeat scroll -20px 65px;
}

.mss {
  background: #963596 url("./images/menu-icon/solutions sub menu/maintenance-support.png") no-repeat scroll 0 65px;
}

.iam {
  background: #c9dbcb url("./images/menu-icon/solutions sub menu/integration-migration.png") no-repeat scroll 0 65px;
}

.services.oneto {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.offcanvas-body {
  padding: 1rem 0rem !important;
}

.offcanvas-body a {
  padding: 0px 8px 8px 18px;
  text-decoration: none;
  font-size: 1.563rem;
  color: #818181;
  display: block;
}

.social-icon ul li {
  display: inline-block;
}

.social-icon ul {
  margin-left: -20px;
}

.head-txt {
  font-size: 2.188rem;
  margin-bottom: 15px;
  font-weight: 500;
  line-height: 1.1;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  color: #ffffff;
}

.head-txt,
.sub-head {
  padding: 0;
}

div#offcanvasNavbar-expand-false {
  background-color: #fff;
  background-image: url(./images/consulting/pattern-bg.webp);
  background-repeat: repeat;
  -webkit-background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  background-position: center center;
}

.services h2 {
  color: #000000;
  font-size: 4.25rem;
  font-weight: 700;
  text-transform: none;
  padding-left: 10px;
}

.services h3 {
  margin-left: 50px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.8rem 0rem;
}

.other-element .row a.nav-link a {
  padding: 8px 8px 8px 25px;
  text-decoration: none;
  font-size: 1.563rem;
  color: #818181;
  display: block;
}

.row.king h3.main-hs:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 5px;
  margin: -5px 0;
  background-color: black;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out 0s;
}

.other-element .row a.nav-link a:hover {
  color: black;
}

.services h3 img {
  width: 40px;
  margin-right: 15px;
}

.other-element .row a.nav-link img {
  width: 40px;
  margin-right: 15px;
}

/* nevbar css end  */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mobilese {
  display: none;
}

.pagesss {
  z-index: 9;
  margin-bottom: 37rem;
  position: relative;
  background-color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* service page css end  */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.image_curtain {
  background: linear-gradient(to bottom right, #f24a4a 0%, #f7be68 100%);
  padding-top: 300px;
  padding-bottom: 300px;
  height: 100vh;
  min-height: 100vh;
}

.image_curtain_2 {
  background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
  padding-top: 300px;
  padding-bottom: 300px;
  height: 100vh;
  min-height: 100vh;
}

.image_curtain_3 {
  background: linear-gradient(to bottom right, #00ffff 0%, #9900ff 100%);
  padding-top: 300px;
  padding-bottom: 300px;
  height: 100vh;
  min-height: 100vh;
}

.subtitle {
  font-size: 1.125rem;
  color: #fff;
  line-height: 1px;
  text-align: left;
  font-family: Gilroy-Medium;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  margin-bottom: -5px;

  margin-bottom: 20px;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

.card {
  border-radius: 40px;
  overflow: hidden;
  border: 0;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.07);
  transition: all 0.15s ease;
}

.animate__animated.animate.bounce.card {
  display: block;
  margin: 0 auto;
  padding: 45px 20px;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease;
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 15px;
}

container.mt-3 img {
  width: 50%;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-body .card-title {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-size: 1.125rem;
  color: #121212;
  text-align: center;
  margin-top: 30px;
}

.card-text {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 0.938rem;
  letter-spacing: 0.3px;
  color: #4e4e4e;
}

.work-box.text-center img {
  width: 20%;
}

section.industry_sec {
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.item-SB {
  height: 100%;
}

section.Package-sec {
  margin-bottom: 50px;
}

.card .container {
  width: 50%;
  background: #f0eef8;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.container:hover>img {
  transform: scale(1.2);
}

.btn {
  background: #eeecf7;
  border: 0;
  color: #5535f0;
  width: 98%;
  font-weight: bold;
  border-radius: 20px;
  height: 40px;
  transition: all 0.2s ease;
}

.btn:hover {
  background: #441cff;
}

.btn:focus {
  background: #441cff;
  outline: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image_curtain_0 {
  background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
  padding-top: 300px;
  padding-bottom: 300px;
  height: 100vh;
  min-height: 100vh;
}

.image_curtain_1 {
  background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
  padding-top: 300px;
  padding-bottom: 300px;
  height: 100vh;
  min-height: 100vh;
}

.who-work-with {
  display: flex;
  padding: 100px 0px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
  margin: 0 auto;
  vertical-align: middle;
  min-height: 100vh;
}

.workflow {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*************************************** New Css Add 10-02-2023 *****************************************/
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
  .who-work-with {
    height: auto !important;
    padding-top: 30px;
  }

  .process-items li {
    display: block;
    padding: 45px 55px;
    -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease;
    -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
  }
}

/*************************************** New Css Add 10-02-2023 end *****************************************/
.who-work-with h2 {
  font-family: "Roboto";
  font-size: 2.25rem;
  color: #fff;
  text-align: center;
}

.who-work-with h4 {
  font-family: "Roboto";
  font-size: 1.25rem;
  color: #222;
  text-align: center;
}

.who-work-with h3 {
  font-family: "Roboto";
  font-size: 1.75rem;
  text-transform: capitalize;
  color: #000;
  padding: 0px;
  margin: 0px;
}

.who-work-with p {
  font-family: "Roboto";
  color: #fff;
  text-align: center;
  font-size: 1.813rem;
  padding: 28px;
  line-height: 45px;
}

.work-box {
  background-color: #fff;
  border-radius: 50px;
  -webkit-box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
  margin: 12px;
  padding-top: 25px;
  padding-bottom: 6px;
}

.work-box svg {
  padding-top: 15px;
}

.text-center {
  text-align: center !important;
}

.section-head {
  margin-bottom: 60px;
}

.section-head h4 {
  position: relative;
  padding: 0;
  color: #f91942;
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 2.125rem;
  font-weight: 700;
  text-align: center;
  text-transform: none;
  margin-bottom: 30px;
}

.section-head h4:before {
  content: "";
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color: #2f2f2f;
}

p.service_text {
  color: #cccccc !important;
  font-size: 1rem;
  line-height: 28px;
  text-align: center;
}

.section-head p,
p.awesome_line {
  color: #818181;
  font-size: 1.25rem;
  line-height: 28px;
  text-align: center;
  width: 80%;
  display: block;
  margin: 0 auto;
}

.extra-text {
  font-size: 2.125rem;
  font-weight: 700;
  color: #2f2f2f;
  margin-bottom: 25px;
  position: relative;
  text-transform: none;
}

.extra-text::before {
  content: "";
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right: 0;
  margin: 0 auto;
}

.extra-text span {
  font-weight: 700;
  color: #f91942;
}

.item-SB {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item-SB:hover {
  background: linear-gradient(to bottom right,
      #00ffffb3 10%,
      #3f0366 100%) !important;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item-SB:hover .item-SB,
.item-SB:hover span.icon {
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
  width: 120px;
  display: block;
  margin: 0 auto;
  height: auto;
}

.item-SB:hover h6,
.item-SB:hover p {
  color: #fff;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item-SB .icon {
  font-size: 2.5rem;
  margin-bottom: 25px;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}

.item-SB .feature_box_col_two {
  color: #f91942;
}

.item-SB .feature_box_col_three {
  color: #f91942;
}

.item-SB .feature_box_col_four {
  color: #f91942;
}

.item-SB .feature_box_col_five {
  color: #f91942;
}

.item-SB .feature_box_col_six {
  color: #f91942;
}

.item-SB p {
  font-size: 1.25rem;
  line-height: 50px;
  text-align: left;
  margin-left: 20px;
  font-weight: 500;
}

.item-SB span.icon {
  display: block;
  margin: 0 auto;
}

.item-SB p a {
  color: black !important;
}

.item-SB p a:hover {
  color: white !important;
  font-weight: bold !important;
}

.item-SB h6 {
  margin-bottom: 20px;
  color: #2f2f2f;
  font-size: 1.563rem;
  margin-top: 10px;
}

.mission p {
  margin-bottom: 10px;
  font-size: 0.938rem;
  line-height: 28px;
  font-weight: 500;
}

.mission i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #f91942;
  border-radius: 50%;
  color: #fff;
  font-size: 1.563rem;
}

.mission .small-text {
  margin-left: 10px;
  font-size: 0.813rem;
  color: #666;
}

.skills {
  padding-top: 0px;
}

.skills .prog-item {
  margin-bottom: 25px;
}

.skills .prog-item:last-child {
  margin-bottom: 0;
}

.skills .prog-item p {
  font-weight: 500;
  font-size: 0.938rem;
  margin-bottom: 10px;
}

.skills .prog-item .skills-progress {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 20px;
  position: relative;
}

.skills .prog-item .skills-progress span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #f91942;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.skills .prog-item .skills-progress span:after {
  content: attr(data-value);
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 0.625rem;
  font-weight: 600;
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 3px 7px;
  border-radius: 30px;
}

.carousel-inner,
.carousel,
.item,
.container,
.fill {
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: cover;
}

.slide-wrapper {
  display: inline;
}

.slide-wrapper .container {
  padding: 0px;
}

/*------------------------------ vertical bootstrap slider----------------------------*/

.carousel-inner>.item.next,
.carousel-inner>.item.active.right {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  top: 0;
  left: 0;
}

.carousel-inner>.item.prev,
.carousel-inner>.item.active.left {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  top: 0;
  left: 0;
}

.carousel-inner>.item.next.left,
.carousel-inner>.item.prev.right,
.carousel-inner>.item.active {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  top: 0;
  left: 0;
}

/*------------------------------- vertical carousel indicators ------------------------------*/
.carousel-indicators {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  right: 10px;
  left: auto;
  width: auto;
}

.carousel-indicators li {
  display: block;
  margin-bottom: 5px;
  border: 1px solid #00a199;
}

.carousel-indicators li.active {
  margin-bottom: 5px;
  background: #00a199;
}

/*-------- Animation slider ------*/

.animated {
  animation-duration: 3s;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -ms-animation-duration: 3s;
  -o-animation-duration: 3s;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}

.carousel-img {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-top: 60px;
}

.item1 .carousel-img img,
.item1.active .carousel-img img {
  max-height: auto;
}

.item .carousel-desc {
  float: none;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  text-align: left;
}

.item h2 {
  font-size: 3.125rem;
}

.item p {
  margin: auto;
}

.item2 .carousel-img img,
.item2.active .carousel-img img {
  max-height: auto;
}

.item2.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
}

.item2 h2,
item2.active h2 {
  visibility: hidden;
  opacity: 0;
}

.item2.active h2.animated {
  visibility: visible;
  opacity: 1;
}

.item .fill {
  padding: 0px 30px;
  display: table;
}

.item .inner-content {
  display: table-cell;
  vertical-align: middle;
}

.item3 ol-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.row:after,
.row:before {
  content: " ";
  display: table;
}

.item3.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
}

.item3 h2,
item3.active h2 {
  visibility: hidden;
  opacity: 0;
}

.item.item3 .carousel-desc {
  text-align: left;
}

.item3.active h2.animated {
  visibility: visible;
  opacity: 1;
}

.item3 p,
item3.active p {
  visibility: hidden;
  opacity: 0;

  width: 100%;
}

.item3.active p.animated {
  visibility: visible;
  opacity: 1;
}

.item .fill {
  padding: 0px 30px;
  display: table;
  display: flex;
  align-items: center;
  alignment-baseline: middle;
  vertical-align: middle;
  justify-content: center;
}

.item .inner-content {
  display: table-cell;
  vertical-align: middle;
}

.item4 .col-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.item4.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
}

.item4 h2,
item4.active h2 {
  visibility: hidden;
  opacity: 0;
}

.item.item4 .carousel-desc {
  text-align: left;
}

.item4.active h2.animated {
  visibility: visible;
  opacity: 1;
}

.item4 p,
item3.active p {
  visibility: hidden;
  opacity: 0;

  width: 100%;
}

.item4.active p.animated {
  visibility: visible;
  opacity: 1;
}

/*  /////////////////////////////////////////*/

.item5 .col-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.item5.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
}

.item.item5 .carousel-desc {
  text-align: left;
}

.item5.active h2.animated {
  visibility: visible;
  opacity: 1;
}

.item5 p,
item3.active p {
  width: 100%;
}

/*  /////////////////////////////////////////*/

/*  /////////////////////////////////////////*/

.item6 ol-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.item6.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
}

.item6 h2,
item6.active h2 {
  visibility: hidden;
  opacity: 0;
}

.item.item6 .carousel-desc {
  text-align: left;
}

.item6.active h2.animated {
  visibility: visible;
  opacity: 1;
}

.item6 p,
item3.active p {
  visibility: hidden;
  opacity: 0;

  width: 100%;
}

.item6.active p.animated {
  visibility: visible;
  opacity: 1;
}

/*  /////////////////////////////////////////*/

/*  /////////////////////////////////////////*/

.item7 ol-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.item7.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
  animation-duration: 2s;
  animation-delay: 0.3s;
}

.item7 h2,
item7.active h2 {
  visibility: hidden;
  opacity: 0;
  transition: all 5s ease;
}

.item.item7 .carousel-desc {
  text-align: left;
}

.item7.active h2.animated {
  visibility: visible;
  opacity: 1;
  animation-delay: 1.5s;
}

.item7 p,
item3.active p {
  visibility: hidden;
  opacity: 0;
  transition: all 5s ease;
  width: 100%;
}

.item7.active p.animated {
  visibility: visible;
  opacity: 1;
  animation-delay: 2.5s;
}

/*  /////////////////////////////////////////*/

/*  /////////////////////////////////////////*/

.item8 .col-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.item8.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
  animation-duration: 2s;
  animation-delay: 0.3s;
}

.item8 h2,
item5.active h2 {
  visibility: hidden;
  opacity: 0;
  transition: all 5s ease;
}

.item.item8 .carousel-desc {
  text-align: left;
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.item8.active h2.animated {
  visibility: visible;
  opacity: 1;
  animation-delay: 1.5s;
}

.item8 p,
item3.active p {
  visibility: hidden;
  opacity: 0;
  transition: all 5s ease;
  width: 100%;
}

.item8.active p.animated {
  visibility: visible;
  opacity: 1;
  animation-delay: 2.5s;
}

/*  /////////////////////////////////////////*/

/*  /////////////////////////////////////////*/

.item9 .col-md-6 {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 49%;
}

.item9.active .carousel-img img.animated {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
  animation-duration: 2s;
  animation-delay: 0.3s;
}

.item9 h2,
item5.active h2 {
  visibility: hidden;
  opacity: 0;
  transition: all 5s ease;
}

.item.item9 .carousel-desc {
  text-align: left;
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.item9.active h2.animated {
  visibility: visible;
  opacity: 1;
  animation-delay: 1.5s;
}

.item9 p,
item3.active p {
  visibility: hidden;
  opacity: 0;
  transition: all 5s ease;
  width: 100%;
}

.item9.active p.animated {
  visibility: visible;
  opacity: 1;
  animation-delay: 2.5s;
}

/*  /////////////////////////////////////////*/

@media (min-width: 992px) {
  ol-md-6 x.carousel-desc {
    width: 100%;
    float: none;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
  }
}

@media (max-width: 991px) {

  .item .carousel-desc,
  .item.item3 .carousel-desc {
    text-align: center;
  }

  .fill {
    height: 100vh !important;
  }

  div#large-header {
    height: 100vh;
  }

  .item .carousel-desc p {
    width: 80%;
  }

  .item13 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item2 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item3 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item4 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item5 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item6 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item7 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item8 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  .item9 ol-md-6 {
    width: 100%;
    text-align: center;
  }

  div#text-5\ text-f .textwidget h3 span {
    font-size: 3.125rem !important;
  }
}

@media (max-width: 768px) {

  .item .carousel-img img,
  .item.active .carousel-img img {
    max-height: auto;
  }

  div#large-header {
    height: 100vh;
  }

  .item h2 {
    font-size: 1.625rem;
    margin-top: 0px;
  }

  .item .carousel-desc p {
    width: 100%;
    font-size: 0.75rem;
  }

  div#text-5\ text-f .textwidget h3 span {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 480px) {
  .item h2 {
    font-size: 1.875rem;
    font-weight: 600;
  }

  .item .carousel-desc p {
    width: 100%;
  }

  /******************************************* New css 10-02-2023 **********************/
  div#large-header {
    max-height: 730px;
  }

  .item .carousel-desc {
    color: #fff;
    text-align: left;
    margin-top: 50px;
  }

  h3.business_style {
    font-size: 3.125rem;
  }

  div#text-5\ text-f .textwidget h3 span {
    font-size: 2.188rem !important;
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner p {
    line-height: 1.4em !important;
  }

  .carousel-img {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 190px;
  }

  .fill {
    height: 918px !important;
  }

  img.mobile {
    width: 300px !important;
  }

  /**************************************    home page main slider css start ***********************************************/

  .item.item8 ol-md-6.text-left {
    margin-top: 0px;
  }

  .item.item9 ol-md-6.itemees2.text-left {
    margin-top: 0px;
  }

  .item.item1 ol-md-6.text-left {
    margin-top: 0px;
  }

  .item.item2 ol-md-6.text-left {
    margin-top: 0px;
  }

  .item.item3 ol-md-6.text-left {
    margin-top: 0px;
  }

  .item.item4 ol-md-6.text-left {
    margin-top: 0px;
  }

  .item.item5 ol-md-6.text-left {
    margin-top: 0px;
  }

  .item.item6 ol-md-6.text-left {
    margin-top: 0px;
  }

  section.process-sec {
    background: rebeccapurple;
  }

  /*********************************************** home page main slider css and ************************/
}


.footer-main {
  background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
  padding: 5em;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

#footer1 h5 {
  padding-left: 10px;
  border-left: 3px solid #eeeeee;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 2rem;
}

#footer1 a {
  color: #ffffff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-size: 0.938rem;
}

#footer1 ul.social li {
  padding: 3px 0;
}

#footer1 ul.social li a i {
  margin-right: 5px;
  font-size: 1.563rem;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer1 ul.social li:hover a i {
  font-size: 1.875rem;
  margin-top: -10px;
}

#footer1 ul.social li a,
#footer1 ul.quick-links li a {
  color: #ffffff;
}

#footer1 ul.social li a:hover {
  color: #eeeeee;
}

#footer1 ul.quick-links li {
  padding: 3px 0;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

#footer1 ul.quick-links li:hover {
  padding: 3px 0;
  margin-left: 5px;
  font-weight: 700;
}

#footer1 ul.quick-links li a i {
  margin-right: 5px;
}

#footer1 ul.quick-links li:hover a i {
  font-weight: 700;
}

@media (max-width: 767px) {
  #footer1 h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
}

.slidehead {
  font-size: 3.125rem;
  animation-delay: 1.5s;
  animation-duration: 1s;
  font-weight: 600;
  color: #fff;
  text-align: left;
  padding: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.slidepara {
  text-align: left !important;
  font-size: 1.75rem !important;
  color: #fff;
  font-weight: 500;
  line-height: 48px;
  padding-left: 20px;
}

.serviceBox {
  color: #000;
  background: linear-gradient(110deg, #31055b, #571fa7);
  font-family: "Roboto", sans-serif;
  padding: 0 30px 30px;
  margin: 20px 15px 0;
  border-radius: 20px 20px 70px 20px;
  position: relative;
  z-index: 1;
}

.serviceBox:before {
  content: "";
  background: #f5f5f5;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  border-radius: 10px 10px 55px 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
}

.serviceBox .service-icon {
  background: linear-gradient(110deg, #ffc107, #ffa20a);
  font-size: 2.5rem;
  text-align: center;
  line-height: 100px;
  width: 100px;
  height: 100px;
  margin: 0 0 15px;
  border-radius: 15px;
  transform: translateY(-20px) translateX(-37px);
  position: relative;
  z-index: 1;
}

.serviceBox .service-icon i {
  transition: all 0.3s ease 0s;
}

.serviceBox:hover .service-icon i {
  transform: rotateX(360deg);
}

.serviceBox .service-icon:before {
  content: "";
  background: #f5f5f5;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}

.serviceBox:hover .service-icon:before {
  opacity: 1;
}

.serviceBox .title {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 10px;
}

.serviceBox .title:after {
  content: "";
  display: block;
  clear: both;
  background-color: #63c9bd;
  height: 7px;
  width: 25px;
  margin: 5px 0 0;
  border-radius: 10px;
}

.serviceBox .description {
  font-size: 1.25rem;
  line-height: 23px;
}

.serviceBox.green,
.serviceBox.green .service-icon {
  color: #10ac84;
  background: linear-gradient(110deg, #1dd1a1, #10ac84);
}

.serviceBox.green .title:after {
  background-color: #10ac84;
}

.serviceBox.pink,
.serviceBox.pink .service-icon {
  color: #e03f7b;
  background: linear-gradient(110deg, #fb638c, #e03f7b);
}

.serviceBox.pink .title:after {
  background-color: #e03f7b;
}

.serviceBox.blue,
.serviceBox.blue .service-icon {
  color: #2e86de;
  background: linear-gradient(110deg, #54a0ff, #2e86de);
}

.serviceBox.blue .title:after {
  background-color: #2e86de;
}

.main-head {
  background-color: #f24a4a;
  height: 5px;
  width: 10%;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
}

.dev-headings {
  color: #383838;
  font-size: 2.25rem;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
  text-align: center;
}

.dev-landing-para {
  color: #383838;
  font-size: 1.25rem !important;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
}

.industryhigh {
  background-color: #f24a4a;
  height: 5px;
  width: 10%;
  display: block;
  margin: 0 auto;
}

.industry-head h2 {
  color: #383838;
  font-size: 2.25rem;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
}

.industry-head p {
  color: #383838;
  font-size: 1.25rem !important;
  line-height: 26px;
  font-weight: 400;
}

.sec06 h2,
.sec02 h2,
.sec03 h2,
.sec05 h2,
.sec050 h2,
.sec055 h2,
.sec0555 h2,
.sec06 h2,
.sec09 h2,
.sec05 h2,
.sec099 h2,
.sec0999 h2,
.sec0101 h2 {
  margin-bottom: 25px;
  color: #fff;
  text-align: left;
  font-size: 3em;
}

.sec09 p,
.sec099 p,
.sec0999 p,
.sec0101 p,
.sec05 p,
.sec050 p,
.sec055 p,
.sec0555 p {
  margin-bottom: 25px;
  color: #fff;
  text-align: left;
  font-size: 1.2em;
}

.left-element h1 {
  position: relative;
  font-size: 1.875rem;
  z-index: 1;
  overflow: hidden;
  text-align: left;
  line-height: 30px;
}

.left-element h1:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 100%;
  height: 4px;
  content: "\a0";
  background-color: #f24a4a;
}

.left-element h1:before {
  margin-left: -50%;
  text-align: right;
}

.right-element h1 {
  position: relative;
  font-size: 1.875rem;
  z-index: 1;
  overflow: hidden;
  text-align: right;
  color: #f24a4a !important;
  font-weight: 700 !important;
  line-height: 30px;
}

.right-element h1:before {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 100%;
  height: 4px;
  content: "\a0";
  background-color: #f24a4a;
}

.right-element h1:before {
  margin-left: -100%;
  text-align: right;
}

.full_width,
.container {
  position: relative !important;
}

.services-call:hover {
  color: #f24a4a !important;
  text-align: center;
  padding: 15px 30px;
  border: 3px solid #f24a4a !important;
  font-size: 1.375rem;
  font-weight: bold;
  background: #fff !important;
  border-radius: 10px;
  cursor: pointer;
}

.nl-form {
  width: 100%;
  margin: 0.3em auto 0 auto;
  font-size: 3rem;
  line-height: 1.5;
  color: #fff !important;
  font-size: 2.5rem !important;
  font-weight: 700;
}

.nl-form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nl-form input,
.nl-form select,
.nl-form button {
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: inline-block;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.nl-form input:focus {
  outline: none;
}

.nl-field {
  display: inline-block;
  position: relative;
  padding-top: 10px;
}

.nl-field.nl-field-open {
  z-index: 10000;
}

.nl-field-toggle,
.nl-form input,
.nl-form select {
  line-height: inherit;
  display: inline-block;
  color: #f9a59f !important;
  cursor: pointer;
  border-bottom: 1px dashed #f9a59f;
}

.nl-field.nl-dd select option {
  position: absolute;
  background: #c73535;
  left: -0.5em;
  top: 10%;
}

option:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.nl-field ul {
  position: absolute;
  visibility: hidden;
  background: #c73535;
  left: -0.5em;
  top: 50%;
  font-size: 80%;
  opacity: 0;
  -webkit-transform: translateY(-40%) scale(0.9);
  -moz-transform: translateY(-40%) scale(0.9);
  transform: translateY(-40%) scale(0.9);
  -webkit-transition: visibility 0s 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: visibility 0s 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
}

.nl-field.nl-field-open ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-50%) scale(1);
  -moz-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  -webkit-transition: visibility 0s 0s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: visibility 0s 0s, opacity 0.3s, -moz-transform 0.3s;
  transition: visibility 0s 0s, opacity 0.3s, transform 0.3s;
}

.nl-field ul li {
  color: #fff;
  position: relative;
}

.nl-dd ul li {
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.nl-dd ul li.nl-dd-checked {
  color: #ffe007;
}

.no-touch .nl-dd ul li:hover {
  background: rgba(0, 0, 0, 0.05);
}

.no-touch .nl-dd ul li:hover:active {
  color: #478982;
}

.nl-ti-text ul {
  min-width: 8em;
}

.nl-ti-text ul li.nl-ti-input input {
  width: 100%;
  padding: 0.2em 2em 0.2em 0.5em;
  border-bottom: none;
  color: #fff;
}

.nl-form .nl-field-go {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
  width: 1.8em;
  text-align: center;
  color: transparent;
}

.nl-field-go:before {
  content: "\2192";
  font-size: 75%;
  color: #fff;
  width: 100%;
  line-height: 2.5;
  display: block;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

input:active::-webkit-input-placeholder,
input:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

input::-moz-placeholder {
  color: #000;
}

input:active::-moz-placeholder,
input:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

input:active::-ms-input-placeholder,
input:focus::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.2);
}

.nl-ti-text ul li.nl-ti-example {
  font-size: 40%;
  font-style: italic;
  font-weight: 400;
  padding: 10px 10px;
  color: #000;
  border-top: 1px dashed rgba(255, 255, 255, 0.7);
}

.nl-ti-text ul li.nl-ti-example em {
  color: #fff;
}

.nl-submit-wrap {
  margin-top: 0.4em;
}

.nl-form .nl-submit {
  line-height: 3;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  background: #fff;
  border: 1px solid #f24a4a;
  border-radius: 5px;
  color: #f24a4a;
  padding: 3px 2em 4px 2em;
  font-size: 40%;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 0.688rem;
  margin-top: 25px;
}

.no-touch .nl-form .nl-submit:hover,
.no-touch .nl-form .nl-submit:active {
  background: #f24a4a;
  color: #fff;
  border: 1px solid #fff;
}

.nl-field nl-dd a:active {
  color: #ffe007 !important;
}

.no-touch .nl-form .nl-submit:hover:before {
  background: #58a199;
}

.nl-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: 9999;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.3s, opacity 0.3s;
  -moz-transition: visibility 0s 0.3s, opacity 0.3s;
  transition: visibility 0s 0.3s, opacity 0.3s;
}

.nl-field.nl-field-open~.nl-overlay {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

::-webkit-input-placeholder {
  color: #909;
}

:-moz-placeholder {
  color: #909;
  opacity: 1;
}

::-moz-placeholder {
  color: #909;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #909;
}

::-ms-input-placeholder {
  color: #909;
}

@media screen and (max-width: 45em) {
  .nl-form {
    font-size: 3em;
  }

  .Custom-images-reset {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 25em) {
  .nl-form {
    font-size: 2em;
  }

  .Custom-images-reset {
    margin-top: 10px !important;
  }
}

input::-webkit-input-placeholder {
  color: #f9a59f;
}

input:-ms-input-placeholder {
  color: #f9a59f;
}

input::-moz-placeholder {
  color: #f9a59f;
}

.Custom-images-reset {
  margin-top: -130px;
}

.custom-form-hide {
  display: none !important;
}

#custom-row .section_inner {
  width: 100% !important;
}

.nl-field-toggled {
  color: #ffe007;
}

.nl-form-toggled:hover {
  color: #f9a59f !important;
}

#custom,
.custom-row-hide {
  display: none !important;
}

section.letstalk-sec {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 50px;
  background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
}

.letheading h3 {
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 60px;
  font-size: 4.375rem;
}

a:hover,
p a:hover {
  color: #f24a4ad1;
}

section.android-sec {
  padding-top: 70px;
  padding-bottom: 50px;
}

.leverage-higt {
  background-color: #f24a4a;
  height: 5px;
  width: 10%;
  display: block;
  margin: 0 auto;
}

.leverage-heading h3 {
  color: #383838;
  font-size: 2.25rem;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
  text-align: center;
}

.leverage-heading p {
  color: #000000;
  font-size: 1.875rem !important;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}

.leverage-heading ul {
  text-align: center;
}

.leverage-heading ul li {
  margin-left: 17px;
  padding: 8px 20px;
  color: #fff;
  background: #383838;
  margin-top: 12px;
  display: inline-block;
  text-align: center;
}

.digital-high {
  background-color: #ffffff;
  height: 5px;
  width: 10%;
}

.digital-heading h3 {
  font-size: 1.625rem;
  color: White !important;
  line-height: 40px;
  font-weight: 700;
  padding: 16px 0px 26px;
}

.digital-heading p {
  color: #fff;
  font-size: 1.875rem !important;
  line-height: 40px;
  font-weight: 400;
}

.digital-heading h3.big {
  color: #000 !important;
}

.digital-heading p.big {
  color: #000 !important;
}

.digital-heading button {
  color: rgb(255, 255, 255);
  border-color: rgb(242, 74, 74);
  background-color: rgb(242, 74, 74);

  font-family: "Montserrat", sans-serif;

  font-size: 0.688rem;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-width: 2px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 30px;
}

.digital-heading button:hover {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  border-color: rgb(242, 74, 74);
  font-size: 0.688rem;
  line-height: 48px;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  background-color: rgba(250, 250, 250, 0);
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-width: 2px;
  padding-left: 25px;
  padding-right: 25px;
}

.digital-img img {
  display: block;
  margin: 0 auto;
  height: 100%;
  min-height: 600px;
}

.digital-head {
  padding-bottom: 50px;
}

.digital-images h3 span {
  font-size: 1.25rem !important;
  line-height: 32px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0px;
  padding-left: 15px;
}

.Skilled-img img {
  display: block;
  margin: 0 auto;
}

.Skilled-main {
  text-align: center;
}

.Skilled-heading h3 {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 22px 2px 10px;
  font-weight: 700;
}

.Skilled-heading p {
  color: #000000;
  font-size: 1.563rem !important;
  line-height: 32px;
  font-style: normal;
  font-weight: 400;
}

.sec01 p,
.sec02 p,
.sec03 p,
.sec05 p,
.sec050 p,
.sec055 p,
.sec0555 p,
.sec06 p,
.sec09 p,
.sec099 p,
.sec0999 p {
  color: #fff;
}

.btns a {
  padding: 7px 35px;
  font-size: 0.875rem;
  font-family: Gilroy-Medium;
  line-height: 26px;
  margin-bottom: 20px;
  color: #fff !important;
  background: #ffa20a;
  background: linear-gradient(-45deg,
      rgba(255, 162, 10, 1) 0,
      rgba(253, 75, 15, 1) 100%);
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  z-index: 99;
  position: relative;
  margin-top: 10px;
  display: inline-block;
}

.subtitle:after {
  content: "";
  background: #fff;
  width: 20px;
  height: 2px;
  display: block;
  margin-top: 15px;
}

.sec11 .subtitle,
.sec10 .subtitle {
  color: #ffffff;
}

.sec11 .subtitle:after,
.sec10 .subtitle:after {
  background: #fff;
}

.sec01 {
  height: 100vh;
  background-size: 37%;
  padding-top: 135px;
  padding-bottom: 50px;
}

.sec01 h6 {
  font-size: 0.938rem;
  line-height: 45px;
  letter-spacing: 3px;
  color: #ef5123;
  font-family: Gilroy-Bold;
  text-transform: uppercase;
  margin: 0;
}

.sec02 {
  height: 830px;
  min-height: 700px;
  background: #f9f9f9;
  position: relative;
}

.sec06 h2,
.sec02 h2,
.sec03 h2,
.sec05 h2,
.sec050 h2,
.sec055 h2,
.sec0555 h2,
.sec06 h2,
.sec09 h2,
.sec09 p,
.sec099 h2,
.sec099 p,
.sec0999 h2,
.sec0999 p,
.sec0101 p {
  margin-bottom: 25px;
  color: #fff;
}

.sec02:after {
  content: "";
  width: 50%;
  height: 835px;
  background-size: cover;
  background-repeat: no-repeat !important;
  top: 0;
  right: 0;
  position: absolute;
  background-position: center center;
}

.sec03 {
  height: 830px;
  min-height: 700px;
  background: #ffffff;
  position: relative;
  z-index: 9999;
}

.sec03:before {
  background-position: center center !important;
  content: "";
  position: absolute;
  width: 50%;
  height: 830px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.sec04 {
  height: 848px;
  min-height: 700px;
  position: relative;
  background-position: left bottom;
  background-size: cover;
}

.sec04 h2 {
  color: #fff;
  margin-bottom: 25px;
}

.sec04 p {
  color: #fff;
  margin-bottom: 30px;
}

.btns-arrow a {
  font-family: Gilroy-SemiBold;
  font-size: 1.125rem;
  color: #000000 !important;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  z-index: 99;
  text-transform: uppercase;
  margin-right: 10px;
  letter-spacing: 1px;
}

.btns-arrow svg {
  margin-left: 10px;
  position: relative;
  top: 5px;
}

.btns-arrow a:hover {
  color: #fff;
  text-decoration: none;
}

.sec050 {
  position: relative;
  background: url("./images/ai/header-left.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 7%;
  background-attachment: fixed;
}

.sec05 {
  position: relative;
  background: url("./images/indexicon/webd-evelopment5.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 93%;
  background-attachment: fixed;
}

.sec055 {
  position: relative;
  background: url("./images/indeximg/Ecommerce-development.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: -4%;
  background-attachment: fixed;
}

.sec0555 {
  position: relative;
  background: url("./images/indexicon/blockchain\ -\ 77.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 8%;
  background-attachment: fixed;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.sec050 a {
  font-size: 0.875rem;
  line-height: 27px;
  color: #ef5123;
  font-family: Gilroy-SemiBold;
  text-transform: uppercase;
}

.sec050 a:hover {
  color: #ef5123;
  text-decoration: none;
}

.sec05 a {
  font-size: 0.875rem;
  line-height: 27px;
  color: #ef5123;
  font-family: Gilroy-SemiBold;
  text-transform: uppercase;
}

.sec05 a:hover {
  color: #ef5123;
  text-decoration: none;
}

.sec055 a {
  font-size: 0.875rem;
  line-height: 27px;
  color: #ef5123;
  font-family: Gilroy-SemiBold;
  text-transform: uppercase;
}

.sec055 a:hover {
  color: #ef5123;
  text-decoration: none;
}

.sec0555 a {
  font-size: 0.875rem;
  line-height: 27px;
  color: #ef5123;
  font-family: Gilroy-SemiBold;
  text-transform: uppercase;
}

.sec0555 a:hover {
  color: #ef5123;
  text-decoration: none;
}

.sec06 {
  height: 830px;
  min-height: 700px;
  background: #f6f6f6;
  position: relative;
}

.sec06:after {
  content: "";
  width: 50%;
  height: 835px;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  position: absolute;
  background-size: 30%;
  background-position: 75%;
  background: url("./images/indeximg/benefits.png");
}

.list-point {
  padding: 0px 0px 0px 20px;
  margin: 0px;
  list-style: none;
}

.list-point li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 15px;
}

.list-point li:before {
  content: "";
  background: rgb(255, 162, 10);
  background: linear-gradient(40deg,
      rgba(255, 162, 10, 1) 0%,
      rgba(253, 75, 15, 1) 100%);
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  left: -9px;
  top: 12px;
  border-radius: 50%;
}

.list-point li:after {
  content: "";
  width: 17px;
  height: 17px;
  display: inline-block;
  position: absolute;
  left: -14px;
  border: 1px solid #ef5123;
  top: 7px;
  border-radius: 50%;
}

.sec06 ul li p {
  margin: 0;
  font-size: 1.25rem;
}

.sec07:before {
  background: url("./images/indeximg/advantage.png");
  background-size: 30%;
  background-position: 75%;
  content: "";
  position: absolute;
  width: 50%;
  height: 830px;
  background-repeat: no-repeat !important;
}

.sec07:after {
  content: "";
  background: url("./images/indeximg/star3.png") -31% top;
  width: 50%;
  height: 835px;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  position: absolute;
  background-size: 30%;
  background-position: 75%;
}

.sec07 {
  height: 830px;
  min-height: 700px;
  background: #1a1a1a;
  position: relative;
  z-index: 99;
}

.sec07 h2,
.sec07 p {
  color: #fff;
  margin-bottom: 25px;
}

.sec07 h2 span,
.sec06 h2 span,
.sec08 h2 span,
.sec05 h2 span,
.sec050 h2 span,
.sec055 h2 span,
.sec0555 h2 span,
.sec04 h2 span,
.sec09 h2 span,
.sec099 h2 span,
.sec0999 h2 span,
.sec10 h2 span,
.sec11 h2 span {
  letter-spacing: -2px;
  color: #fff;
}

.sec07 h6 {
  font-size: 1.375rem;
  font-family: Gilroy-SemiBold;
  color: #fff;
  line-height: 28px;
  margin-bottom: 10px;
}

.sec07 h6:after {
  content: "";
  background: #ef5123;
  width: 20px;
  height: 2px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tran {
  color: #ef5123;
}

.sec08 {
  height: 492px;
  min-height: 492px;
}

.sec08 h4 {
  font-size: 1.25rem;
  letter-spacing: -1px;
  margin-top: 40px;
  font-family: Gilroy-Regular;
}

.sec09 {
  position: relative;
  background: url("./images/indeximg/mobileapp-development.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 100%;
  background-attachment: fixed;
}

.sec099 {
  position: relative;
  background: url("./images/indexicon/uiux-header02.png");
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 0%;
  background-attachment: fixed;
}

.sec0999 {
  position: relative;
  background: url("./images/indeximg/games-header3.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 101%;
  background-attachment: fixed;
}

.sec0101 {
  position: relative;
  background: url("./images/indeximg/digital-marketing2.png");
  background-repeat: no-repeat;
  background-size: 52%;
  background-position: 105%;
  background-attachment: fixed;
}

.sec09 p a {
  color: #fff;
  font-size: 0.938rem;
  line-height: 24px;
  padding-left: 16px;
}

.sec099 p a {
  color: #fff;
  font-size: 0.938rem;
  line-height: 24px;
  padding-left: 16px;
}

.sec0999 p a {
  color: #fff;
  font-size: 0.938rem;
  line-height: 24px;
  padding-left: 16px;
}

.sec0101 p a {
  color: #fff;
  font-size: 0.938rem;
  line-height: 24px;
  padding-left: 16px;
}

.sec10 {
  background: #000 url("./images/indeximg/bottom.png") no-repeat;
  height: 885px;
  min-height: 700px;
  position: relative;
  background-size: 30%;
  background-position: 75%;
}

.sec10 h2 {
  color: #fff;
  margin-bottom: 25px;
}

.sec10 p {
  color: #fff;
  margin-bottom: 30px;
  margin-top: 33px;
}

.mt-155 {
  margin-top: 155px;
}

.sec11 {
  height: 580px;
  min-height: 580px;
  background: #f84525;
  position: relative;
}

.sec11 h2 {
  color: #fff;
  margin-bottom: 25px;
  margin-top: 45px;
}

.sec11 p {
  margin-bottom: 25px;
  color: #282526;
}

.sec11 p a {
  color: #fff;
  font-size: 0.938rem;
  line-height: 24px;
  padding-left: 16px;
}

.slider_solution .slick-next.slick-arrow {
  z-index: 99;
  border: 0px;
  font-size: 0;
  height: 108px;
  width: 54px;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  top: 40%;
}

.slider_solution .slick-prev.slick-arrow {
  position: absolute;
  left: 0;
  top: 40%;
  z-index: 99;
  border: 0px;
  font-size: 0;
  height: 108px;
  width: 54px;
}

button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.slider_solution h4 {
  color: #fff;
  font-family: Gilroy-Bold;
  font-size: 1.563rem;
  position: absolute;
  z-index: 999999999;
  bottom: 60px;
  left: 25px;
}

.shez {
  padding-left: 0px;
  position: relative;
  left: 100px;
}

.shez1 {
  position: relative;
  padding-right: 50px;
}

.slid {
  position: relative;
  height: 580px;
}

.slid img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.slider_solution {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 1920px) {
  .image_curtain {
    background: linear-gradient(to bottom right, #f24a4a 0%, #f7be68 100%);
    padding: 50px 40px !important;
    height: 100vh;
    min-height: 100vh;
  }

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 10px;
    color: #fff;
    font-size: 2.5rem;
  }

  .sec06 p,
  .sec02 p,
  .sec03 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p,
  .sec06 p,
  .sec09 p,
  .sec099 p,
  .sec0999 p,
  .sec0101 p {
    margin-bottom: 5px;
    color: #fff;
    font-size: 1.25rem;
    line-height: 30px;
  }

  .image_curtain_0 {
    background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
    padding: 50px 40px !important;
    height: 100vh;
    min-height: 100vh;
  }

  .image_curtain_1 {
    background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
    padding: 50px 40px !important;
    height: 100vh;
    min-height: 100vh;
  }

  .image_curtain_2 {
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
    padding: 50px 40px !important;
    height: 100vh;
    min-height: 100vh;
  }

  .image_curtain_3 {
    background: linear-gradient(to bottom right, #00ffff 0%, #9900ff 100%);
    padding: 50px 40px !important;
    height: 100vh;
    min-height: 100vh;
  }

  .vc_col-12.vc_col-sm-6.vc_col-md-6.vc_col-lg-6.pr-50.mt-auto.mb-auto.image_curtain_0 h2 {
    font-size: 3.313rem !important;
    line-height: 65px;
  }

  .vc_col-12.vc_col-sm-6.vc_col-md-6.vc_col-lg-6.pr-50.mt-auto.mb-auto.image_curtain_1 h2 {
    font-size: 3.313rem !important;
    line-height: 65px;
  }

  .vc_col-12.vc_col-sm-6.vc_col-md-6.vc_col-lg-6.pr-50.mt-auto.mb-auto.image_curtain h2 {
    font-size: 3.313rem !important;
    line-height: 65px;
  }

  .vc_col-12.vc_col-sm-6.vc_col-md-6.vc_col-lg-6.pr-50.mt-auto.mb-auto.image_curtain_3 h2 {
    font-size: 3.313rem !important;
    line-height: 65px;
  }

  .vc_col-12.vc_col-sm-6.vc_col-md-6.vc_col-lg-6.pr-50.mt-auto.mb-auto.image_curtain_2 h2 {
    font-size: 3.313rem !important;
    line-height: 65px;
  }
}

.mobile_mobile {
  padding-top: 120px;
}

@media (min-width: 1200px) and (max-width: 1429px) {
  h2.slidehead {
    font-size: 2.625rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 25px;
    padding-left: 20px;
  }

  section.mainbanner-sec {
    height: auto;
    display: flex;
    vertical-align: middle;
  }

  .qode-expanding-images-inner img {
    display: block;
    margin: 0 auto;
  }

  .Bucketimg {
    position: relative;
  }

  .qode-expanding-images-inner {
    position: relative;
  }

  .qode-hero-image {
    position: absolute;
    top: 95px;
    left: 0%;
    right: 0%;
  }

  .mainbanner-head {
    display: flex;
    vertical-align: middle;
    position: relative;
  }

  .top-bar-border {
    background-color: #ffffff;
    height: 5px;
    width: 8%;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  section.howwe-sec {
    background: linear-gradient(135deg, #141e30 0, #243b55 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .howmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
    text-align: center;
  }

  .howworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  section.WhyWork-sec {
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .Whymain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .whyworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  section.OurWork-sec {
    height: auto;
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);

    color: #fff;
    padding: 50px 40px !important;
    margin-bottom: 60px;
  }

  .ourmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .ourworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  .image_curtain {
    background: linear-gradient(to bottom right, #f24a4a 0%, #f7be68 100%);
  }

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 5px;
    color: #fff;
    font-size: 1.875rem;
  }

  .sec06 p,
  .sec02 p,
  .sec03 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p,
  .sec06 p,
  .sec09 p,
  .sec099 p,
  .sec0999 p,
  .sec0101 p {
    margin-bottom: 5px;
    color: #fff;
    font-size: 1.375rem;
    line-height: 30px;
  }

  p.slidepara {
    line-height: 40px;
    font-size: 1.375rem;
  }

  .slidehead {
    font-size: 2.25rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    color: #fff;
    text-align: left;
    padding: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }

  .image_curtain_0 {
    background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
  }

  .image_curtain_1 {
    background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
  }

  .image_curtain_2 {
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
  }

  .image_curtain_3 {
    background: linear-gradient(to bottom right, #00ffff 0%, #9900ff 100%);
  }
}

@media screen and (max-width: 992px) {
  .image_curtain {
    background: linear-gradient(to bottom right, #f24a4a 0%, #f7be68 100%);
  }

  .image_curtain_0 {
    background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
  }

  .image_curtain_1 {
    background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
  }

  .image_curtain_2 {
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
  }

  .image_curtain_3 {
    background: linear-gradient(to bottom right, #00ffff 0%, #9900ff 100%);
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .image_curtain {
    background: linear-gradient(to bottom right, #f24a4a 0%, #f7be68 100%);
  }

  .image_curtain_0 {
    background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
  }

  .image_curtain_1 {
    background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
  }

  .image_curtain_2 {
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
  }

  .image_curtain_3 {
    background: linear-gradient(to bottom right, #00ffff 0%, #9900ff 100%);
  }
}

@media (min-width: 501px) and (max-width: 4000px) {
  #mobile_menu_button {
    display: none !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .sec01 h1 {
    font-size: 2.063rem;
    line-height: 40px;
  }

  h2,
  h3 {
    font-size: 1.875rem;
    line-height: 36px;
  }

  .perag {
    margin-left: 60px;
  }

  .sec09 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;
    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec099 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;
    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec0999 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;

    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec05 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;

    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec050 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;

    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec055 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;

    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec0555 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;

    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .sec0101 {
    position: relative;
    background-repeat: no-repeat;
    background: #fff;

    background-size: 30%;
    background-position: 85%;
    background-attachment: fixed;
  }

  .image_curtain {
    background: linear-gradient(to bottom right, #f24a4a 0%, #f7be68 100%);
    padding: 2rem 0rem;
  }

  .image_curtain_0 {
    background: linear-gradient(-45deg, #45108a 30%, #3d065f 70%, #10054d 100%);
    padding: 2rem 0rem;
  }

  .image_curtain_1 {
    background: linear-gradient(to bottom right, #6dab3c 0%, #0f3fd8 100%);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .image_curtain_2 {
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .image_curtain_3 {
    background: linear-gradient(to bottom right, #00ffff 0%, #9900ff 100%);
    padding-top: 30px;
    padding-bottom: 100px;
  }

  #Services {
    width: 85% !important;
  }
}

p.description {
  color: #000;
}

.textwidget h3 {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  text-transform: none;
}

div#text-5\ text-f .textwidget h3 span {
  font-size: 4.063rem !important;
}

section.main-footer {
  width: 100%;
  color: #fff;
  position: fixed;
  bottom: 0px;
  z-index: 0;
}

footer {
  background-color: #383838;
  padding-bottom: 70px;
}

.column2.footer_col2 {
  text-align: right;
}

.icon_text_inner p {
  font-size: 0.875rem !important;
}

.icon_text_inner::before {
  content: "\f041";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  color: #fff;
  font-size: 2.5rem;
  position: relative;
  right: -50px;
  top: 40px;
}

.icon_text_inner-1 p {
  font-size: 0.875rem !important;
}

.icon_text_inner-1::before {
  content: "\f095";
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
  color: #fff;
  font-size: 2.5rem;
  position: relative;
  right: -50px;
  top: 40px;
}

.bottom-footer {
  background-color: #191919;
  padding: 1rem 0rem;
}

.botomright-text {
  text-align: right;
}

item-SB p a {
  text-decoration: none;
}

.pipe {
  color: #fff;
  font-size: 0.813rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.privacy a {
  color: #f24a4a !important;
  font-size: 0.813rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.icon_text_inner p {
  line-height: 25px;
}

.icon_text_inner-1 p {
  line-height: 25px;
}

/* solution page start  */

nav.mb-3.navbar {
  background-color: rgba(232, 232, 232, 0.6);
  z-index: 9999;
}

nav.mb-3.navbar.navbar-light {
  margin-top: -10px;
}

div#myCarousel {
  margin-top: -90px;
}

div#offcanvasNavbar-expand-false {
  z-index: 99999;
}

.main-img img {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: inherit;
  z-index: 20;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 20;
  opacity: 1;
  visibility: inherit;
  max-height: 650px;
}

.row.quick-sec {
  margin-top: -120px;
}

.Quick-maas {
  background-color: #f8f6f1;
  text-align: center;
  height: 100%;
  min-height: 300px;
}

.Quick-head h3 {
  text-align: center;
  color: #282b2d;
  font-size: 2.3rem;
  line-height: 40px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  text-transform: none;
  padding-top: 70px;
}

.nav-visibility {
  background-color: rgba(232, 232, 232, 0.6);
}

.Quick-swift {
  background-color: #fff;
  box-shadow: 0 7px 16px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 45px 20px;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease;
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  text-align: center;
  height: 100%;
}

.quick-swift-img h3 {
  color: #393945;
  font-size: 0.875rem;
  line-height: 17px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-top: 30px;
}

.quick-fir {
  position: relative;
}

.Finance-main {
  text-align: center;
  padding: 20px;
}

.finance-para p {
  color: #000;
  color: #000000;
  font-size: 1.75rem !important;
  line-height: 32px;
  font-style: normal;
  font-weight: 400;
}

.finance-sec img {
  margin-top: -65px;
}

.finance-head h3 {
  padding-top: 14px !important;
  padding-bottom: 16px !important;
}

section.Fintech-sec {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(to bottom right,
      #5b9ea5d1 0%,
      #992f2feb 100%) !important;
  padding-top: 3px;
}

.Fintech-Head h3 {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  text-transform: none;
  text-align: center;
  color: #fff;
  padding-bottom: 50px;
}

.digital-images ul {
  list-style: none;
  display: flex;
  margin-left: -50px;
}

.digital-images ul li img {
  width: 300px;
}

.digital-head.our-fint .digital-heading p {
  padding-bottom: 40px;
}

.digital-images.Fintech-img ul li p {
  padding-left: 20px;
}

.Finance-main {
  margin-right: 5% !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  background-color: #e0f2fd !important;
  border-left-color: #bce1ff !important;
  border-left-style: solid !important;
  border-right-color: #bce1ff !important;
  border-right-style: solid !important;
  border-bottom-color: #bce1ff !important;
  border-bottom-style: solid !important;
  height: 100%;
}

.Finance-main.sec2 {
  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;
  border-left-width: 1px !important;
  background-color: #f7fef8 !important;
  border-left-color: #d5f7dd !important;
  border-left-style: solid !important;
  border-right-color: #d5f7dd !important;
  border-right-style: solid !important;
  border-top-color: #d5f7dd !important;
  border-top-style: solid !important;
  border-bottom-color: #d5f7dd !important;
  border-bottom-style: solid !important;
}

.Finance-main.sec1 {
  background-color: #fdf7f2 !important;
  border: 1px solid #fce3c3 !important;
}

.choce-btg-sec h3 {
  text-align: left;
  color: #fff;
  font-size: 2.5rem;
  line-height: 54px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: none;
  text-align: center;
}

.choce-btg-sec p {
  padding-top: 15px;
  color: #fff;
  font-size: 1.5rem;
  line-height: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: none;
}

.main-form input.form-control {
  background-color: transparent;
  color: #fff;
}

.main-form textarea#inputAddress {
  background-color: transparent;
  color: #fff;
}

.choce-btg-head {
  padding-top: 30px;
  padding-bottom: 0px;
}

.get-head {
  padding-bottom: 50px;
}

.get-head h3 {
  text-align: center;
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 45px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: none;
}

.choce-btg-head h1 {
  text-align: center;
  font-size: 2.813rem;
  font-weight: 800;
  padding-bottom: 20px;
  color: #fff;
  padding-top: 30px;
}

section.digital-sec .row {
  padding-top: 30px;
  padding-bottom: 15px;
}

.main-img img {
  height: 100%;
  min-height: 215px;
}

.get-intech {
  display: inline-block;
}

.main-form form.row.g-3 input {
  padding: 20px 25px;
}

.main-form form.row.g-3 button {
  background-color: #81bf0b;
  color: #fff;
  border: 0 none transparent;
  height: 65px;
  display: block;
  margin: 0 auto;
  line-height: 3;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  background: #fff;
  border: 1px solid #f24a4a;
  border-radius: 5px;
  color: #f24a4a;
  padding: 3px 2em 4px 2em;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 3px;
  margin-top: 25px;
}

.main-form {
  padding-top: 50px;
  padding-bottom: 100px;
}

/* consulting page  */
section.Fintech-sec1 {
  padding-top: 60px !important;
  padding-right: 30px !important;
  padding-bottom: 60px !important;
  padding-left: 30px !important;
  background: linear-gradient(to bottom right,
      #4daaaf 0%,
      #e9db76ed 100%) !important;
  padding-top: 3px;
}

button.slick-arrow.slick-next {
  display: none !important;
}

button.slick-arrow.slick-prev {
  display: none !important;
}


@media only screen and (min-width: 1200px) {}

@media only screen and (max-width: 2000px) {}

@media (min-width: 1024px) and (max-width: 1280px) {
  .who-work-with h3 {
    font-size: 1.25rem;
    text-transform: capitalize;
    color: #000;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
  }

  .main-heabs h3 {
    position: absolute;
    top: 80px;
    z-index: 999;
    left: 0%;
    right: 0%;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
  }

  .botomright-text p {
    font-size: 1.225rem;
    color: #ffffff;
    line-height: 40px;
  }

  .services h2 {
    color: #000000;
    font-size: 3rem;
    font-weight: 700;
    text-transform: none;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .column2.footer_col2 {
    text-align: right;
    padding-top: 80px;
  }

  div#text-5\ text-f {
    padding-top: 95px;
  }

  .sec0555 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 8%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec0101 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 52%;
    background-position: 105%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec0999 {
    position: relative;

    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 101%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec055 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: -4%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec099 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: 0%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec05 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 93%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec050 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 7%;
    background-attachment: fixed;
    background-position-y: 20%;
  }

  .sec09 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 100%;
    background-attachment: fixed;
    background-position-y: 30%;
  }

  .slid0 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid1 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid2 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid3 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid4 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid5 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid6 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid7 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid8 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid9 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .image_curtain {
    height: auto;

    padding: 100px 40px !important;
  }
  .image_curtain_0 {
    height: auto;
    padding: 100px 40px !important;
  }

  .image_curtain_1 {
    height: auto;

    padding: 100px 40px !important;
  }

  .image_curtain_2 {
    height: auto;

    padding: 100px 40px !important;
  }

  .image_curtain_3 {
    height: auto;

    padding: 100px 40px !important;
  }

  h2.slidehead {
    font-size: 1.875rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.188rem !important;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
    padding-left: 20px;
  }

  .sec0101 p,
  .sec02 p,
  .sec03 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p,
  .sec06 p,
  .sec09 p,
  .sec099 p,
  .sec0999 p {
    color: #fff;
    font-size: 1.625rem;
    line-height: 34px;
    margin-bottom: 5px;
  }

  .sec0101 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec099 h2,
  .sec0999 h2 {
    color: #fff;
    font-size: 1.875rem;
    margin-bottom: 20px;
  }

  h2.slidehead {
    font-size: 2.625rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 25px;
    padding-left: 20px;
  }

  h2.slidehead {
    font-size: 2.625rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 25px;
    padding-left: 20px;
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.75rem !important;
    color: #fff;
    font-weight: 500;
    line-height: 42px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  footer {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .who-work-with h3 {
    font-size: 1.25rem;
    text-transform: capitalize;
    color: #000;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
  }

  .column2.footer_col2 {
    text-align: right;
    padding-top: 80px;
  }

  .main-heabs h3 {
    position: absolute;
    top: 80px;
    z-index: 999;
    left: 0%;
    right: 0%;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
  }

  div#text-5\ text-f {
    padding-top: 125px;
  }

  div#text-5\ text-f .textwidget h3 span {
    font-size: 2.875rem !important;
  }

  .image_curtain {
    height: auto;

    padding: 100px 40px !important;
  }

  .image_curtain_0 {
    height: auto;
    padding: 100px 40px !important;
  }

  .image_curtain_1 {
    height: auto;

    padding: 100px 40px !important;
  }

  .image_curtain_2 {
    height: auto;

    padding: 100px 40px !important;
  }

  .image_curtain_3 {
    height: auto;

    padding: 100px 40px !important;
  }

  h2.slidehead {
    font-size: 1.875rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.188rem !important;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
    padding-left: 20px;
  }

  .qode-hero-image img {
    width: 400px;
  }

  .qode-frame-image.qode-lazy-image img {
    width: 100%;
    min-width: 230px;
    height: 100%;
    min-height: 100px !important;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .slidehead {
    font-size: 2.5rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    color: #fff;
    text-align: left;
    padding: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }

  h2.slidehead {
    font-size: 2.25rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 25px;
    padding-left: 20px;
  }

  .image_curtain {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_0 {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_1 {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_2 {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_3 {
    height: auto;
    padding-bottom: 90px;
  }

  .slid0 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid1 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid2 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid3 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid4 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid5 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid6 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid7 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid8 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid9 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .sec06 p,
  .sec02 p,
  .sec03 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p,
  .sec06 p,
  .sec09 p,
  .sec099 p,
  .sec0999 p,
  .sec0101 p {
    margin-bottom: 5px;
    color: #fff;
    font-size: 1.375rem;
    line-height: 26px;
  }

  .serviceBox {
    color: #000;
    background: linear-gradient(110deg, #31055b, #571fa7);
    font-family: "Roboto", sans-serif;
    padding: 0 30px 30px;
    margin: 20px 7px 0;
    border-radius: 20px 20px 70px 20px;
    position: relative;
    z-index: 1;
  }

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 1.875rem;
  }
}

@media only screen and (min-width: 1440px) {
  .slidehead {
    font-size: 3.125rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    color: #fff;
    text-align: left;
    padding: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }

  .heading-wrapper img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .image_curtain {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_0 {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_1 {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_2 {
    height: auto;
    padding-bottom: 90px;
  }

  .image_curtain_3 {
    height: auto;
    padding-bottom: 90px;
  }

  .qode-hero-image img {
    width: 100%;
    height: 100%;
    max-height: 600px !important;
    max-width: 64%;
  }

  .qode-hero-image {
    position: absolute;
    top: 50px;
    left: 0%;
    right: 0%;
  }

  .sec06 p,
  .sec02 p,
  .sec03 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p,
  .sec06 p,
  .sec09 p,
  .sec099 p,
  .sec0999 p,
  .sec0101 p {
    margin-bottom: 5px;
    color: #fff;
    font-size: 1.625rem;
    line-height: 36px;
  }

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 4.25rem;
    font-weight: 700;
  }

  section.bockets {
    height: 100vh;
  }

  .btgbouquet {
    background-color: #f24a4a;
    padding: 50px 170px;
    position: absolute;
    bottom: 0px;
    z-index: 999999999;
    left: 15%;
    right: 15%;
    color: #fff;
    text-align: center;
  }

  section.WhyWork-sec {
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: 100vh;
    min-height: 100vh;
  }

  section.OurWork-sec {
    height: 100vh;
    min-height: 100vh;
  }

  section.howwe-sec {
    height: 100vh;
    min-height: 100vh;
  }

  .slid0 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid1 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid2 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid3 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid4 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid5 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid6 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid7 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid8 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }

  .slid9 {
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
    padding: 0rem;
  }
}

@media only screen and (max-width: 1600px) {
  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

@media only screen and (min-width: 1600px) {

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 3.125rem;
    font-weight: 700;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .slid0 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid1 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid2 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid3 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid4 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid5 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid6 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid7 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid8 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid9 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }
}

@media only screen and (max-width: 1440px) {
  .slid0 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }
  .slid1 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid2 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid3 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid4 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid5 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid6 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid7 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid8 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid9 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.75rem !important;
    color: #fff;
    font-weight: 500;
    line-height: 40px;
    padding-left: 20px;
  }

  .main-container .flower1 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower2 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower3 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower4 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower5 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower6 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower7 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .main-container .flower8 {
    width: 170px;
    height: 150px;
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
    padding: 1rem;
  }

  .heading-wrapper img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .h-50vh {
    height: 34vh !important;
    width: 100% !important;
    border-radius: 25px;
    border: 2px solid #963596;
    margin: 10px;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .qode-hero-image img {
    width: 375px;
  }

  .who-work-with h3 {
    font-size: 1.25rem;
    text-transform: capitalize;
    color: #000;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
  }

  .main-heabs h3 {
    position: absolute;
    top: 80px;
    z-index: 999;
    left: 0%;
    right: 0%;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
  }

  .qode-hero-image {
    position: absolute;
    top: 20px;
    left: 0%;
    right: 0%;
  }

  .img.qode-frame-image.qode-lazy-image img {
    width: 100%;
    min-width: 230px;
    height: 100%;
    min-height: 100px !important;
  }

  section.mainbanner-sec {
    height: auto;
    display: flex;
    vertical-align: middle;
    padding-bottom: 0px;
  }

  .col-12.col-md-6.col-sm-12.col-xl-6.col-lg-6.masasa {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  .who-work-with {
    display: flex;
    padding: 100px 0px;
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    margin: 0 auto;
    vertical-align: middle;
  }

  .mainbanner-head {
    display: flex;
    vertical-align: middle;
    position: relative;
  }

  .top-bar-border {
    background-color: #ffffff;
    height: 5px;
    width: 8%;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  section.howwe-sec {
    background: linear-gradient(135deg, #141e30 0, #243b55 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .howmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
    text-align: center;
  }

  .howworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  section.WhyWork-sec {
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .Whymain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .whyworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  .digital-img img {
    display: block;
    margin: 0 auto;
    height: auto;
  }

  section.OurWork-sec {
    height: auto;
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);

    color: #fff;
    padding: 50px 40px !important;
    margin-bottom: 60px;
  }

  .ourmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .ourworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  .services h3 {
    margin-left: 0px;
    font-weight: bold;
    white-space: nowrap;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    overflow: scroll;
    background-color: #f1f2f2;
    padding: 0.8rem;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    overflow: scroll;
    background-color: #f1f2f2;
    padding: 0.8rem;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .offcanvas-body a {
    padding: 0px 8px 8px 0px;
    text-decoration: none;
    font-size: 1.563rem;
    color: #818181;
    display: block;
  }

  .h-50vh {
    height: 30vh !important;
    width: 100% !important;
    border-radius: 25px;
    border: 2px solid #963596;
    margin: 5px;
  }

  .head-txt {
    font-size: 1.625rem;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 1.1;
    font-family: tungstenw05-medium, Oswald, sans-serif;
    color: #000;
  }

  .other-element .row a.nav-link a {
    padding: 8px 8px 8px 0px;
    text-decoration: none;
    color: #818181;
    display: block;
  }

  .slid0 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid1 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid2 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid3 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid4 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid5 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid6 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid7 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid8 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid9 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }
}

@media only screen and (min-width: 1960px) {
  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1025px) {
  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
    padding: 1rem;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

@media only screen and (max-width: 2000px) and (min-width: 1700px) {
  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

@media only screen and (min-width: 1440px) {
  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
    padding: 1rem;
  }

  .other-consulting {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    background-color: #f1f2f2;

    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

@media (min-width: 0px) and (max-width: 820px) {
  .Ai-chatbot {
    margin-top: 5rem;
  }

  .Service-img {
    margin-top: 5rem;
  }

  .choce-btg-head h1 {
    text-align: center;
    font-size: 2.375rem;
    font-weight: 800;
    padding-bottom: 20px;
    color: #fff;
    padding-top: 30px;
  }

  .col-12.col-sm-12.col-md-6.col-lg-3.pr-50.mt-auto.mb-auto.industr {
    display: none;
  }

  .col-12.col-md-6.col-sm-12.col-lg-6.col-xl-6.aswsa {
    margin-top: 100px;
  }

  .who-work-with {
    display: flex;
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    margin: 0 auto;
    vertical-align: middle;
  }

  section.mainbanner-sec {
    height: auto;
    display: flex;
    vertical-align: middle;
    padding-top: 80px;
    padding-bottom: 0px;
  }

  .mainbanner-head {
    display: flex;
    vertical-align: middle;
    position: relative;
  }

  .top-bar-border {
    background-color: #ffffff;
    height: 5px;
    width: 8%;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  section.howwe-sec {
    background: linear-gradient(135deg, #141e30 0, #243b55 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .howmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
    text-align: center;
  }

  .howworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  section.WhyWork-sec {
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .Whymain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .whyworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  .digital-img img {
    display: block;
    margin: 0 auto;
    height: auto;
  }

  section.OurWork-sec {
    height: auto;
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);

    color: #fff;
    padding: 50px 40px !important;
    margin-bottom: 60px;
  }

  .ourmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .ourworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  .main-heading h3 {
    color: #191919;
    font-family: inherit;
    font-size: 3.125rem;
    line-height: 60px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1.25px;
    text-transform: none;
  }

  .main-heading p {
    color: #716e6e;
    font-family: inherit;
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
  }

  .main-heabs h3 {
    position: absolute;
    top: 30px;
    z-index: 999;
    left: 0%;
    right: 0%;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .Quick-head h3 {
    text-align: center;
    color: #282b2d;
    font-size: 1.9rem;
    line-height: 30px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: none;
    padding-top: 70px;
  }

  .finance-head h3 {
    padding-top: 14px !important;
    padding-bottom: 16px !important;
    font-size: 1.3rem;
    font-weight: 700;
  }

  .finance-para p {
    color: #000;
    color: #000000;
    font-size: 1.25rem !important;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
  }

  .slidehead {
    font-size: 1.875rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    color: #fff;
    text-align: left;
    padding: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }

  h2.slidehead {
    font-size: 1.875rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  /* .slid {
    padding-top: 160px;
    height: 100vh;
    max-height: 100vh;
  } */

  .slid0 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid1 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid2 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid3 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid4 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid5 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid6 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid7 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid8 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid9 {
    padding: 1rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.25rem !important;
    color: #fff;
    font-weight: 400;
    line-height: 22px;
  }

  .services h2 {
    font-size: 2.188rem;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .left-img {
    height: auto;
  }

  section.android-sec {
    margin-top: 70px;
  }

  span.main-logo.navbar-brand img {
    width: 350px;
    height: auto;
  }

  section.main-footer {
    width: 100%;
    color: #fff;
    position: relative;
    bottom: 0px;
    z-index: 0;
  }

  .other-element .row {
    padding-left: 20px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
    z-index: 99999;
    padding: 1rem;
  }

  .other-solution .row {
    padding-left: 20px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .other-solution {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
    z-index: 99999;
  }

  .other-solution .row {
    padding-left: 10px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .other-consulting .row {
    padding-left: 10px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .other-consulting {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
  }

  .pagesss {
    justify-content: center;
    align-items: center;
    z-index: 9;
    margin-bottom: 0px;
    position: relative;
    background-color: #fff;
  }

  div#text-5\ text-f .textwidget h3 span {
    font-size: 2.5rem !important;
  }

  .short-line,
  p {
    margin: 10px 0px;
    font-size: 1rem;
    color: #ffffff;
    line-height: 24px;
  }

  .footer_bottom .textwidget p span {
    font-size: 1.125rem !important;
  }

  .short-line,
  p {
    margin: 10px 0px;
    font-size: 1rem;
    color: #ffffff;
    line-height: 18px;
    padding-top: 10px;
  }

  .icon_text_inner p {
    font-size: 0.75 !important;
    line-height: 22px;
  }

  .icon_text_inner-1 p {
    font-size: 0.75 !important;
    line-height: 22px;
  }

  .left-img {
    width: 70%;
    height: auto;
    background-image: linear-gradient(to right, #227569, #6d8ea698);
    padding: 40px;
  }

  footer {
    background-color: #383838;
    padding-bottom: 70px;
    padding-top: 0px;
  }

  .heading-1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2.25rem;
    color: #ffffff;
    line-height: 40px;
  }

  .left-img .short-line,
  p {
    margin: 10px 0px;
    font-size: 1.875rem;
    color: #ffffff;
    line-height: 35px;
  }

  .leverage-heading h3 {
    color: #383838;
    font-size: 2rem;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
    text-align: center;
  }

  .leverage-heading p {
    color: #000000;
    font-size: 1.125rem !important;
    line-height: 35px;
    font-weight: 400;
    text-align: center;
  }

  .digital-heading h3 {
    font-size: 1.5rem;
    color: White !important;
    line-height: 35px;
    font-weight: 700;
    padding: 16px 0px 10px;
  }

  .digital-heading p {
    color: #fff;
    font-size: 1.25rem !important;
    line-height: 35px;
    font-weight: 400;
  }

  .Skilled-heading p {
    color: #000000;
    font-size: 1.25rem !important;
    line-height: 25px;
    font-style: normal;
    font-weight: 400;
  }

  .Skilled-heading h3 {
    font-size: 1.25rem;
    font-weight: 300;
    padding: 22px 2px 10px;
    font-weight: 700;
  }

  .Skilled-main {
    text-align: center;
    margin-bottom: 35px;
  }

  .who-work-with h2 {
    font-family: "Roboto";
    font-size: 1.875rem;
    color: #fff;
    text-align: center;
    line-height: 0px;
  }

  .who-work-with p {
    font-family: "Roboto";
    font-size: 1.125rem;
    color: #fff;
    text-align: center;
    font-size: 1.125rem !important;
    padding: 20px;
    line-height: 35px;
  }

  .who-work-with h3 {
    font-family: "Roboto";
    font-size: 0.875rem;
    text-transform: capitalize;
    color: #000;
    padding: 0px;
    margin: 0px;
  }

  #footer1 h5 {
    padding-left: 10px;
    border-left: 3px solid #eeeeee;
    padding-bottom: 6px;
    margin-bottom: 20px;
    color: #ffffff;
    font-size: 1rem;
  }

  .letheading h3 {
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 40px;
    font-size: 2.75rem;
  }

  .nl-form {
    width: 100%;
    margin: 0.3em auto 0 auto;
    font-size: 3rem;
    line-height: 1.5;
    color: #fff !important;
    font-size: 1.2rem !important;
    font-weight: 700;
  }

  .card-body .card-title {
    font-family: "Lato", sans-serif;
    font-weight: 700;
    letter-spacing: 0.3px;
    font-size: 0.875rem;
    color: #121212;
    text-align: center;
    margin-top: 20px;
  }

  .animate__animated.animate.bounce.card {
    display: block;
    margin: 0 auto;
    padding: 5px 10px;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 15px;
  }

  section.industry_sec {
    margin-top: 40px;
  }

  .industry-head h2 {
    color: #383838;
    font-size: 2.25rem;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 10px;
  }

  .industry-head p {
    color: #383838;
    font-size: 1.25rem !important;
    line-height: 26px;
    font-weight: 400;
    padding-bottom: 30px;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_0 {
    height: auto;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_1 {
    height: auto;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_2 {
    height: auto;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain {
    height: auto;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_3 {
    height: auto;
  }

  .sec099 {
    position: relative;
    background-size: 59%;
    background-position: 100%;
    background-attachment: fixed;
  }

  .sec099 {
    position: relative;
    background-size: 59%;
    background-position: 100%;
    background-attachment: fixed;
  }

  .sec099 {
    position: relative;
    background-size: 59%;
    background-position: 100%;
    background-attachment: fixed;
  }

  .sec099 {
    position: relative;
    background-size: 59%;
    background-position: 100%;
    background-attachment: fixed;
  }

  .sec099 {
    position: relative;
    background-size: 59%;
    background-position: 100%;
    background-attachment: fixed;
  }

  .serviceBox {
    color: #000;
    background: linear-gradient(110deg, #31055b, #571fa7);
    font-family: "Roboto", sans-serif;
    padding: 0 35px 0px;
    margin: 20px 7px 0;
    border-radius: 20px 20px 70px 20px;
    position: relative;
    z-index: 1;
  }

  .serviceBox .title {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec05 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 25px;
    color: #fff;
    text-align: left;
    font-size: 2em;
  }

  .sec01 p,
  .sec02 p,
  .sec03 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p,
  .sec06 p,
  .sec09 p,
  .sec099 p,
  .sec0999 p,
  .sec0101 p {
    font-size: 1.2rem;
    color: #fff;
    line-height: 35px;
  }

  .sec055 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: -4%;
    background-attachment: fixed;
  }
}

@media only screen and (max-width: 767px) {
  .slid0 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }
  .slid1 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid2 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid3 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid4 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid5 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
  }

  .slid6 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid7 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid8 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }

  .slid9 {
    padding: 0rem;
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh
  }
}

/* Responsive  */

@media only screen and (max-width: 600px) {
  section.main-footer {
    width: 100%;
    color: #fff;
    position: relative;
    bottom: 0px;
    z-index: 0;
  }

  .pagesss {
    justify-content: center;
    align-items: center;
    z-index: 9;
    margin-bottom: 0px;
    position: relative;
    background-color: #fff;
  }
}

@media only screen and (max-device-width: 767px) {
  .services.oneto a {
    display: block;
  }

  .slid0 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid1 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid2 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid3 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid4 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid5 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid6 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid7 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }


  .slid8 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  .slid9 {
    /* padding: 0rem; */
    /* margin-top: 3.5rem; */
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100vh;
  }

  section.who-work-with {
    height: 100vh;
    min-height: 100vh;
  }

  .heading-wrapper img {
    width: 300px;
    display: block;
    margin: 0 auto;
  }

  .qode-hero-image img {
    width: 230px;
  }

  .qode-hero-image {
    position: absolute;
    top: 10px;
    left: 0%;
    right: 0%;
  }

  section.mainbanner-sec {
    height: auto;
    display: flex;
    vertical-align: middle;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mainbanner-head {
    display: flex;
    vertical-align: middle;
    position: relative;
  }

  .top-bar-border {
    background-color: #ffffff;
    height: 5px;
    width: 8%;
    display: block;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  section.howwe-sec {
    background: linear-gradient(135deg, #141e30 0, #243b55 100%);
    color: #fff;
    padding: 50px 40px !important;
    height: auto;
  }

  .howmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
    text-align: center;
  }

  .howworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  section.WhyWork-sec {
    background: linear-gradient(to bottom right, #04f7af 0%, #000099 100%);
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  .Whymain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .whyworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  section.OurWork-sec {
    height: auto;
    background: linear-gradient(to bottom right, #765ba5 0%, #9f2727 100%);

    color: #fff;
    padding: 50px 40px !important;
    margin-bottom: 60px;
  }

  .ourmain-head h3 {
    color: #fff;
    font-size: 2.25rem;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    padding: 16px 0px 26px;
  }

  .ourworkpara p {
    font-size: 1.563rem !important;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
  }

  .main-heading h3 {
    color: #191919;
    font-family: inherit;
    font-size: 3.125rem;
    line-height: 60px;
    font-style: normal;
    font-weight: bold;
    letter-spacing: -1.25px;
    text-transform: none;
  }

  .main-heading p {
    color: #716e6e;
    font-family: inherit;
    font-size: 1.625rem;
    line-height: 38px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
  }

  .footer_bottom {
    text-align: center;
  }

  .leverage-heading ul li {
    width: 100%;
  }

  .leverage-heading ul li {
    width: 100%;
    padding: 20px;
    margin-left: 0px;
  }

  .leverage-heading ul {
    text-align: center;
    margin-left: -55px;
    margin-top: -20px;
  }

  .leverage-heading p {
    margin-bottom: 70px;
  }

  .botomright-text {
    text-align: center;
  }

  .other-element .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  section.Service-img.type {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  .left-img.laravel {
    width: 100%;
    height: 800px;
    padding: 50px;
  }

  section.Service-img.laravel {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  .left-img.type {
    width: 100%;
    height: 800px;
    padding: 50px;
  }

  .main-heabs h3 {
    position: absolute;
    top: 0px;
    z-index: 999;
    left: 0%;
    right: 0%;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }

  .digital-images h3 span {
    font-size: 0.875rem !important;
    line-height: 32px;
    text-transform: capitalize;
    color: #fff;
    font-weight: 400;
    letter-spacing: 0px;
    padding-left: 15px;
  }

  .why-choce h3 {
    padding: 50px 0px;
    text-align: center;
    font-size: 2.3rem;
    font-weight: 700;
  }

  .row.quick-sec {
    margin-top: -60px;
  }

  .Quick-head h3 {
    text-align: center;
    color: #282b2d;
    font-size: 1.7rem;
    line-height: 40px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: none;
    padding-top: 50px;
  }

  .other-solution {
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
    overflow: scroll;
  }

  .offcanvas-body a {
    padding: 0px 8px 0px 2px;
    text-decoration: none;
    font-size: 1.563rem;
    color: #818181;
    display: block;
  }

  .other-consulting {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
  }

  .who-work-with p {
    font-family: "Roboto";
    color: #fff;
    text-align: center;
    font-size: 1.813rem;
    padding: 20px;
    line-height: 30px;
  }

  .work-box {
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
    margin: 12px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .maines {
    background-color: #ffe53b;
    padding-top: 275px !important;
    padding-bottom: 160px !important;
    background: linear-gradient(to bottom right, #00ffffb3 0%, #3f0366 100%);

    min-height: 100vh;
    font-family: "Fira Sans", sans-serif;
  }

  .services h2 {
    font-size: 2.188rem;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  span.main-logo.navbar-brand img {
    width: 280px;
    height: auto;
  }

  .navbar-brand {
    margin-right: 0px !important;
  }

  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
    z-index: 99999;
    padding: 0.5rem;
  }

  .other-solution {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
    z-index: 99999;
  }

  .other-consulting {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: 100vw;
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 0%;
    z-index: 99999;
  }

  .letheading h3 {
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 60px;
    font-size: 2.5rem;
  }

  .nl-form {
    width: 100%;
    margin: 0.3em auto 0 auto;
    font-size: 3rem;
    line-height: 1.5;
    color: #fff !important;
    font-size: 1.5rem !important;
    font-weight: 700;
  }

  .desk-top {
    display: none;
  }

  .mobilese {
    display: block;
  }

  h2.slidehead {
    font-size: 1.375rem;
    padding-top: 2rem;
  }

  p.slidepara {
    font-size: 0.875rem !important;
    line-height: 20px;
  }

  .sec06 h2,
  .sec02 h2,
  .sec03 h2,
  .sec05 h2,
  .sec050 h2,
  .sec055 h2,
  .sec0555 h2,
  .sec06 h2,
  .sec09 h2,
  .sec05 h2,
  .sec099 h2,
  .sec0999 h2,
  .sec0101 h2 {
    margin-bottom: 25px;
    color: #fff;
    text-align: left;
    font-size: 2rem;
  }

  .sec09 p,
  .sec099 p,
  .sec0999 p,
  .sec0101 p,
  .sec05 p,
  .sec050 p,
  .sec055 p,
  .sec0555 p {
    color: #fff;
    margin-left: 0px;
    text-align: left;
    font-size: 1rem;
    line-height: 26px;
  }

  .image_curtain {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .image_curtain_0 {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .image_curtain_1 {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  footer {
    background-color: #383838;
    padding-bottom: 70px;
    padding-top: 0px;
  }

  section.main-footer {
    width: 100%;
    color: #fff;
    position: relative;
    bottom: 0px;
    z-index: 0;
  }

  .pagesss {
    justify-content: center;
    align-items: center;
    z-index: 9;
    margin-bottom: 0px;
    position: relative;
    background-color: #fff;
  }

  .Quick-swift {
    display: block;
    margin-bottom: 25px;
  }

  .Finance-main {
    display: block;
    margin-bottom: 40px;
    margin-left: 10px;
  }

  div#text-5\ text-f .textwidget h3 span {
    font-size: 2.5rem !important;
  }

  .icon_text_inner p {
    font-size: 0.75rem !important;
    line-height: 24px;
  }

  .icon_text_inner-1 p {
    font-size: 0.75rem !important;
    line-height: 24px;
  }

  .botomright-text .textwidget.custom-html-widget p {
    font-size: 1.25rem;
  }

  .digital-img img {
    display: block;
    margin: 0 auto;
    height: auto;
    min-height: auto;
  }

  .Fintech-Head h3 {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    text-align: center;
    color: #fff;
    padding-bottom: 50px;
    padding-top: 30px;
  }

  .digital-heading p {
    color: #fff;
    font-size: 1.5rem !important;
    line-height: 40px;
    font-weight: 400;
  }

  .main-form form.row.g-3 button {
    width: 100%;
    font-size: 0.8rem;
  }

  .choce-btg-sec h3 {
    text-align: left;
    color: #fff;
    font-size: 1.5rem;
    line-height: 30px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: none;
    text-align: center;
  }

  .choce-btg-sec p {
    padding-top: 15px;
    text-align: left;
    color: #fff;
    font-size: 1rem;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
  }

  .left-img.asas {
    width: 100%;
    height: 800px;
  }

  .left-img {
    width: 100%;
    height: 800px;
    background-image: linear-gradient(to right, #227569, #6d8ea698);
    padding: 50px;
  }

  section.android-sec {
    padding-bottom: 50px;
  }

  .left-img .short-line,
  p {
    margin: 10px 0px;
    font-size: 1.125rem !important;
    color: #ffffff;
    line-height: 40px;
  }

  .work-box.text-center h3 {
    font-size: 1.125rem;
  }

  .heading-1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2.125rem;
    color: #ffffff;
    line-height: 44px;
  }

  .other-solution .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .other-consulting .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  section.Service-img.ethere {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  .Ai-chatbot {
    height: auto;
    width: 100%;
    background-image: url(./images/Services/AI-Chatbot/AIchatbot-Headerimage.jpg);
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  .Service-img {
    height: auto;
    width: 100%;
    background-image: url(./images/Services/androidapp-header01.png);
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.sda {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
    /* margin-top: 5.5rem; */
  }

  section.Service-img.ds {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.imd {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.sasasa {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.social-media {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  .botomright-text .textwidget.custom-html-widget p {
    margin-top: -25px;
  }

  section.Service-img.google-pay {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.privat {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.uxres {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.bigcom {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  .leverage-heading ul {
    text-align: center;
    margin-left: -55px;
    margin-right: -12px;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_3 {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_0 {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_1 {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain_2 {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .col-12.col-sm-6.col-md-6.col-lg-6.col-xl-6.pr-50.mt-auto.mb-auto.image_curtain {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  section.Service-img.cloud {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.testing {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.design-con {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.webapp {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.mainten {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.realstat {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.demand {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.marketan {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.saas {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.emailma {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.nft {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.hyper {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.Angular {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.virtual {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.magen {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.pyth {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.Brand {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.unity {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.augm {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }

  section.Service-img.poduc {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    padding: 0px 0px;
    background-position: bottom right !important;
    background-size: initial !important;
    padding-bottom: 50px;
  }
}

@media only screen and (device-width: 320px) {
  span.main-logo.navbar-brand img {
    width: 250px;
    height: auto;
  }

  .navbar>.container-fluid {
    padding: 0px !important;
  }

  .navbar-brand {
    margin-right: 0px !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .services.oneto a {
    display: block;
  }

  .who-work-with h3 {
    font-size: 1.25rem;
    text-transform: capitalize;
    color: #000;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
  }

  .pagesss {
    justify-content: center;
    align-items: center;
    z-index: 9;
    position: relative;
    background-color: #fff;
  }

  .short-line,
  p {
    margin: 10px 0px;
    font-size: 0.875rem;
    color: #ffffff;
    line-height: 40px;
  }

  .botomright-text p {
    color: #ffffff;
    line-height: 40px;
    font-size: 1.125rem !important;
  }

  .footerspan {
    color: rgb(255, 255, 255);
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.30435em;
  }

  .column2.footer_col2 {
    text-align: right;
    padding-top: 80px;
  }

  .image_curtain {
    display: flex !important;
    flex-flow: column wrap !important;
    justify-content: center !important;
    padding: 100px 40px !important;
  }

  .image_curtain_0 {
    display: flex !important;
    flex-flow: column wrap !important;
    justify-content: center !important;
    padding: 100px 40px !important;
  }

  .image_curtain_1 {
    display: flex !important;
    flex-flow: column wrap !important;
    justify-content: center !important;
    padding: 100px 40px !important;
  }

  .image_curtain_2 {
    display: flex !important;
    flex-flow: column wrap !important;
    justify-content: center !important;

    padding: 100px 40px !important;
  }

  .image_curtain_3 {
    display: flex !important;
    flex-flow: column wrap !important;
    justify-content: center !important;
    padding: 100px 40px !important;
  }

  h2.slidehead {
    font-size: 1.875rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.188rem !important;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
  .image_curtain {
    padding: 100px 40px !important;
  }

  .image_curtain_0 {
    padding: 100px 40px !important;
  }

  .image_curtain_1 {
    padding: 100px 40px !important;
  }

  .image_curtain_2 {
    padding: 100px 40px !important;
  }

  .image_curtain_3 {
    padding: 100px 40px !important;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .botomright-text p {
    margin: 0px;
    font-size: 1.225rem;
    color: #ffffff;
    line-height: 40px;
  }

  .who-work-with h3 {
    font-size: 1.25rem;
    text-transform: capitalize;
    color: #000;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
  }

  .main-heabs h3 {
    position: absolute;
    top: 80px;
    z-index: 999;
    left: 0%;
    right: 0%;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
  }

  .left-img {
    width: 85%;
    height: 420px;
    background-image: linear-gradient(to right, #227569, #6d8ea698);
    padding: 50px;
  }

  .image_curtain {
    height: auto;
    min-height: 100vh;

    padding: 100px 40px !important;
  }

  .image_curtain_0 {
    height: auto;
    min-height: 100vh;

    padding: 100px 40px !important;
  }

  .image_curtain_1 {
    height: auto;
    min-height: 100vh;

    padding: 100px 40px !important;
  }

  .image_curtain_2 {
    height: auto;
    min-height: 100vh;

    padding: 100px 40px !important;
  }

  .image_curtain_3 {
    height: auto;
    min-height: 100vh;

    padding: 100px 40px !important;
  }

  h2.slidehead {
    font-size: 1.875rem;
    animation-delay: 1.5s;
    animation-duration: 1s;
    font-weight: 600;
    padding-bottom: 15px;
    padding-left: 20px;
  }

  .slidepara {
    text-align: left !important;
    font-size: 1.188rem !important;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
    padding-left: 20px;
  }
}

@media only screen and (device-width: 912px) {
  .other-element {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-solution {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }

  .other-consulting {
    overflow: scroll;
    background-color: #f1f2f2;
    transition: 0.5s;
    width: calc(100vw - 399px);
    height: 100%;
    position: absolute;
    background-blend-mode: overlay;
    top: 0px;
    right: 100%;
  }
}

.image_curtain {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.image_curtain_0 {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.image_curtain_1 {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.image_curtain_2 {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.image_curtain_3 {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.ourkey-sec {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

@media only screen and (min-device-width: 600px) and (max-device-width: 767px) {
  .sec09 {
    background: #ffffff !important;
  }
}

@media only screen and (min-width: 821px) {
  .ser-btn {
    display: none !important;
  }
}

@media only screen and (max-width: 425px) {
  .offcanvas.offcanvas-end {
    border: 0px !important;
    width: 100vw !important;
  }
}


.ser-btn {
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 991px) {

  section.mainbanner-sec {
    padding-top: 0px;
  }
}

section.mainbanner-sec {
  min-height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}

section.bockets {
  margin-top: calc(10vh - 0.6rem);
  min-height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}

.serviceBox {
  height: 370px;
}



@media (min-width: 768px) and (max-width: 820px) {
  .other-solution .row {
    width: 60vw;
    margin: 0rem auto;
  }
  
  .other-consulting .row {
    width: 60vw;
    margin: 0rem auto;
  }
}

@media (max-width: 767px) {
  .Ai-chatbot {
    margin-top: 4.34rem;
  }

  .Service-img {
    margin-top: 4.34rem;
  }
}

@media (max-width: 320px) {
  .Ai-chatbot {
    margin-top: 4.34rem;
  }

  .Service-img {
    margin-top: 4rem;
  }
}


.vc_hidden-sm {
    width: 80vw;
}