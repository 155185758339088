main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.thanks-text {
  background: linear-gradient(to right, #6dab3c 40%, #0f3fd8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 1rem 0rem;
    font-weight: bold;
}

.para {
  font-size: 4.5rem;
  font-weight: bold;
} 