@font-face {
	font-family: 'Berkshire Swash';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/berkshireswash/v16/ptRRTi-cavZOGqCvnNJDl5m5XmN_qs42.ttf) format('truetype');
  }
  .Btgbouquet{
	height: 900px;
  }
  .main-container {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
	margin-bottom: 100px;
  }
  .main-container .heading-wrapper {
	position: absolute;
	font-family: 'Berkshire Swash', cursive;
	top: 50%;
	left: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%);
	z-index: 99999999;
  }
  .main-container .heading-wrapper h1 {
    font-size: 38px;
    text-align: center;
}
.btgbouquet h1 {
    font-size: 43px;
	line-height: 1;
	font-weight: bold;
}
.btgbouquet {
    background-color: #f24a4a;
    padding: 60px 200px;
    position: absolute;
    /* top: 679px; */
    bottom: 40px;
    z-index: 999999999;
    left: 15%;
    right: 15%;
	color: #fff;
	text-align: center;

}
  .main-container .flower1 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/ai/header-left.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower2 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/2.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower3 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/3.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower4 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/4.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower5 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/5.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower6 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/6.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower7 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/7.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower {
	width: 220px;
    height: 200px;
    /* background-image: url('../../images/about-images/1.png'); */
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    /* background-color: #fff; */
  }
  .main-container .flower8 {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/8.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  .main-container .flower.tl {
	top: 170px;
	left: 370px;
	transition: 2.0s;
  }
  .main-container .flower.tl.active {
	top: 170px;
	left: 0px;
	transition: 2.0s;
  }
  .main-container .flower.bl {
	bottom: 330px;
	left: 370px;
	transition: 2.0s;
  }
  .main-container .flower.bl.active {
	bottom: 330px;
	left: 0px;
	transition: 2.0s;
  }
  .main-container .flower.tr {
	top: 170px;
	right:370px;
	transition: 2.0s;
  }
  .main-container .flower.tr.active {
	top: 170px;
	right: 0px;
	transition: 2.0s;
  }
  .main-container .flower.br {
	bottom: 330px;
	right: 370px;
	transition: 2.0s;
  }
  .main-container .flower.br.active {
	bottom: 330px;
	right: 0px;
	transition: 2.0s;
  }




  .main-container .flower.tls {
	top: 170px;
	left: 500px;
	transition: 2.0s;
  }
  .main-container .flower.tls.active {
	top: 170px;
	left: 255px;
	transition: 2.0s;
  }
  .main-container .flower.bs {
	bottom: 330px;
	left: 500px;
	transition: 2.0s;
  }
  .main-container .flower.bs.active {
	bottom: 330px;
	left: 255px;
	transition: 2.0s;
  }
  .main-container .flower.ts {
	top: 170px;
	right: 500px;
	transition: 2.0s;
  }
  .main-container .flower.ts.active {
	top: 170px;
	right: 255px;
	transition: 2.0s;
  }
  .main-container .flower.bss {
	bottom: 330px;
	right: 500px;
	transition: 2.0s;
  }
  .main-container .flower.bss.active {
	bottom: 330px;
	right: 255px;
	transition: 2.0s;
  }
  /* .main-container .flowers {
	width: 220px;
    height: 200px;
    background-image: url('../../images/about-images/2.png');
    background-size: cover;
    z-index: 50;
    position: absolute;
    display: block;
    background-color: #fff;
  }
  
  .main-container .flowers.tl {
	top: 170;
	left: 370px;
	transition: 2.0s;
  }
  .main-container .flowers.tl.active {
	top: 170px;
	left: 0px;
	transition: 2.0s;
  }
  .main-container .flowers.bl {
	bottom: 330px;
	left: 370px;
	transition: 2.0s;
  }
  .main-container .flowers.bl.active {
	bottom: 330px;
	left: 0px;
	transition: 2.0s;
  }
  .main-container .flowers.tr {
	top: 170px;
	right:370px;
	transition: 2.0s;
  }
  .main-container .flowers.tr.active {
	top: 170px;
	right: 0px;
	transition: 2.0s;
  }
  .main-container .flowers.br {
	bottom: 330px;
	right: 370px;
	transition: 2.0s;
  }
  .main-container .flowers.br.active {
	bottom: 330px;
	right: 0px;
	transition: 2.0s;
  } */
  .content {
	margin-top: 20px;
	width: 100%;
	/* height: 500px; */
	background-color: lightpink;
  }
 
  
  @media only screen and (min-width: 1440px){
  .btgbouquet {
	  background-color: #f24a4a;
	  padding: 50px 170px;
	  position: absolute;
	  /* top: 679px; */
	  bottom: 0px;
	  z-index: 999999999;
	  left: 15%;
	  right: 15%;
	  color: #fff;
	  text-align: center;
  }
  .main-container .flower.tl {
	top: 140px;
	left: 370px;
	transition: 2.0s;
  }
  .main-container .flower.tl.active {
	top: 140px;
	left: 0px;
	transition: 2.0s;
  }
  .main-container .flower.bl {
	bottom: 230px;
	left: 370px;
	transition: 2.0s;
  }
  .main-container .flower.bl.active {
	bottom: 230px;
	left: 0px;
	transition: 2.0s;
  }
  .main-container .flower.tr {
	top: 140px;
	right:370px;
	transition: 2.0s;
  }
  .main-container .flower.tr.active {
	top: 140px;
	right: 0px;
	transition: 2.0s;
  }
  .main-container .flower.br {
	bottom: 230px;
	right: 370px;
	transition: 2.0s;
  }
  .main-container .flower.br.active {
	bottom: 230px;
	right: 0px;
	transition: 2.0s;
  }




  .main-container .flower.tls {
	top: 140px;
	left: 500px;
	transition: 2.0s;
  }
  .main-container .flower.tls.active {
	top: 140px;
	left: 255px;
	transition: 2.0s;
  }
  .main-container .flower.bs {
	bottom: 230px;
	left: 500px;
	transition: 2.0s;
  }
  .main-container .flower.bs.active {
	bottom: 200px;
	left: 255px;
	transition: 2.0s;
  }
  .main-container .flower.ts {
	top: 140px;
	right: 500px;
	transition: 2.0s;
  }
  .main-container .flower.ts.active {
	top: 140px;
	right: 255px;
	transition: 2.0s;
  }
  .main-container .flower.bss {
	bottom: 230px;
	right: 500px;
	transition: 2.0s;
  }
  .main-container .flower.bss.active {
	bottom: 230px;
	right: 255px;
	transition: 2.0s;
  }
}


  @media only screen and (max-width: 1440px) {
    .main-container .flower1 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower2 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower3 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower4 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower5 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower6 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower7 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower8 {
        width: 170px;
        height: 150px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
	.main-container .flower.tls.active {
		top: 170px;
		left: 170px;
		transition: 2.0s;
	}
	.main-container .flower.bs.active {
		bottom: 330px;
		left: 170px;
		transition: 2.0s;
	}
	.main-container .flower.tl.active {
		top: 170px;
		left: -20px;
		transition: 2.0s;
	}
	.main-container .flower.bl.active {
		bottom: 330px;
		left: -20px;
		transition: 2.0s;
	}
	.main-container .flower.ts.active {
		top: 170px;
		right: 155px;
		transition: 2.0s;
	}
	.main-container .flower.bss.active {
		bottom: 330px;
		right: 155px;
		transition: 2.0s;
	}
	.main-container .flower.tr.active {
		top: 170px;
		right: -25px;
		transition: 2.0s;
	}
	.main-container .flower.br.active {
		bottom: 330px;
		right: -25px;
		transition: 2.0s;
	}
	.btgbouquet {
		background-color: #f24a4a;
		padding: 60px 100px;
		position: absolute;
		/* top: 679px; */
		bottom: 65px;
		z-index: 999999999;
		left: 10%;
		right: 10%;
	}
	.btgbouquet h1{
		font-size: 35px;
		text-align: center;
		
	}

}
@media only screen and (max-width: 1024px) {
	.main-container .heading-wrapper {
		position: absolute;
		font-family: 'Berkshire Swash', cursive;
		top: 42%;
		left: 50%;
		background-color: #fff;
		transform: translate(-50%, -50%);
		z-index: 99999999;
	}
	/* .heading-wrapper img {
		width: 100%;
		min-width: 480px;
		display: block;
		margin: 0 auto;
	} */
	img.qode-frame-image.qode-lazy-image img{
		width: 100%;
		min-width: 230px;
		height: 100%;
		min-height: 100px !important;
	}
	.main-container {
		width: 100%;
		/* height: 100vh; */
		/* background-color: lightblue; */
		position: relative;
		overflow: hidden;
		margin-bottom: 0px;
	}
	.btgbouquet {
		background-color: #f24a4a;
		padding: 60px 70px;
		position: absolute;
		/* top: 679px; */
		bottom: 15px;
		z-index: 999999999;
		left: 10%;
		right: 10%;
		bottom: 205px;
	}
	.main-container .flower1 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}
	.main-container .flower2 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}.main-container .flower3 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}.main-container .flower4 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}.main-container .flower5 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}.main-container .flower6 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}
	.main-container .flower.tls.active {
		top: 170px;
		left: 110px;
		transition: 2.0s;
	}
	.main-container .flower.bs.active {
		bottom: 330px;
		left: 110px;
		transition: 2.0s;
	}
	.main-container .flower.tl.active {
		top: 170px;
		left: -20px;
		transition: 2.0s;
	}
	.main-container .flower.bl.active {
		bottom: 330px;
		left: -20px;
		transition: 2.0s;
	}
	.main-container .flower.ts.active {
		top: 170px;
		right: 45px;
		transition: 2.0s;
	}
	.main-container .flower.bss.active {
		bottom: 330px;
		right: 45px;
		transition: 2.0s;
	}
	.main-container .flower7 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}.main-container .flower8 {
		width: 140px;
		height: 110px;
		/* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
		background-size: cover;
		z-index: 50;
		position: absolute;
		display: block;
		background-color: #fff;
	}
	.main-container .flower.tr.active {
		top: 170px;
		right: -90px;
		transition: 2.0s;
	}
	/* .main-container .flower.tr.active {
		top: 170px;
		right: -60px;
		transition: 2.0s;
	} */
	.main-container .flower.br.active {
		bottom: 330px;
		right: -90px;
		transition: 2.0s;
	}
	.btgbouquet h1 {
		font-size: 20px;
		text-align: center;
	}
}
@media only screen and (device-width: 768px) {
	.main-container .heading-wrapper {
		position: absolute;
		font-family: 'Berkshire Swash', cursive;
		top: 35%;
		left: 50%;
		background-color: #fff;
		transform: translate(-50%, -50%);
		z-index: 99999999;
	}
	.qode-hero-image img {
		width: 260px;
	}
    .main-container .flower1 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower2 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower3 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower4 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower5 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower6 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower7 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
    .main-container .flower8 {
           width: 120px;
    height: 95px;
        /* background-image: url(http://localhost:3000/static/media/1.47b1cee….png); */
        background-size: cover;
        z-index: 50;
        position: absolute;
        display: block;
        background-color: #fff;
    }
	.main-container .flower.tls.active {
		top: 170px;
		left: 80px;
		transition: 2.0s;
	}
	.main-container .flower.bs.active {
		bottom: 330px;
		left: 80px;
		transition: 2.0s;
	}
	.main-container .flower.tl.active {
		top: 170px;
		left: -20px;
		transition: 2.0s;
	}
	.main-container .flower.bl.active {
		bottom: 330px;
		left: -20px;
		transition: 2.0s;
	}
	.main-container .flower.ts.active {
		top: 170px;
		right: -2px;
		transition: 2.0s;
	}
	.main-container .flower.bss.active {
		bottom: 330px;
		right: -2px;
		transition: 2.0s;
	}
	.main-container .flower.tr.active {
		top: 170px;
		right: -105px;
		transition: 2.0s;
	}
	.main-container .flower.br.active {
		bottom: 330px;
		right: -105px;
		transition: 2.0s;
	}
	.btgbouquet {
		background-color: #f24a4a;
		padding: 60px 100px;
		position: absolute;
		/* top: 679px; */
		bottom: 65px;
		z-index: 999999999;
		left: 10%;
		right: 10%;
	}
	.btgbouquet h1{
		font-size: 35px;
		text-align: center;
		
	}

}


@media only screen and (max-width: 480px) {
	.main-container {
		width: 100%;
		height: 50vh;
		/* background-color: lightblue; */
		position: relative;
		overflow: hidden;
		margin-bottom: 0px;
	}

.btgbouquet {
    background-color: #f24a4a;
    padding: 20px 30px;
    position: absolute;
    /* top: 679px; */
    bottom: 15px;
    z-index: 999999999;
    left: 0%;
    right: 0%;
    bottom: 30px;
}
.main-container .flower1 { 
	display: none;
}
.main-container .flower2 { 
	display: none;
}
.main-container .flower3 { 
	display: none;
}
.main-container .flower4 { 
	display: none;
}
.main-container .flower5 { 
	display: none;
}
.main-container .flower6 { 
	display: none;
}
.main-container .flower7 { 
	display: none;
}
.main-container .flower8 { 
	display: none;
}
}